<template>
    <div class='data-wrapper'>
        <div class='data-section__title'>
            <div class='data-section__title_border'/>
            <div>{{title}}</div>
        </div>
        <div class='data-section'>
            <div v-for='(item, index) in list' :key='`${title}-${index}`' class='data-section__item'>
                <div class='data-section__item_value'>{{item.value}}</div>
                <div class='data-section__item_name'>{{item.key}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['title', 'list']
    }
</script>

