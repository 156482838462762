<template>
    <div class="order-section">
        <div class="order-section__item">
            <div class="order-section__name">房源</div>
            <div class="order-section__content">{{detail.address}}</div>
        </div>
        <div class="order-section__item">
            <div class="order-section__name">附加说明</div>
            <div class="order-section__content text-wrap" @click="more">
                {{detail.remark ? detail.remark : '无'}}

                <img src="@/assets/book_icon_next@3x.png" class="text-wrap__icon" v-if="detail.remark"/>
            </div>
        </div>

        <intro-drawer :toggle='desc' :title='"附加说明"' v-on:close='close'>
            <p style='padding: 10px; font-size: 11pt;'>{{detail.remark}}</p>
        </intro-drawer>
    </div>
</template>

<script>
    import IntroDrawer from '../Apartment/drawer'

    export default {
        props: ['detail'],
        components: {
            IntroDrawer
        },
        data () {
            return {
                desc: false
            }
        },
        methods: {
            more () {
                if (this.detail.remark) this.desc = true
            },
            close () {
                this.desc = false
            }
        }
    }
</script>

<style lang="less" scoped>
    .text-wrap {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        padding-right: 10px;
        .text-wrap__icon {
            position: absolute;
            height: 12px;
            right: 0;
            top: calc((100% - 12px)/2);
        }
    }
</style>
