import Vue from 'vue'
import Vuex from 'vuex'

import AuthModule from '@/vuex/modules/auth'
import CommonModule from '@/vuex/modules/common'
import HouseModule from '@/vuex/modules/house'
import PromotionModule from '@/vuex/modules/promotion'
import AgencyModule from '@/vuex/modules/agency'
import ApartmentModule from '@/vuex/modules/apartment'
import OrderModule from '@/vuex/modules/order'
import TopicModule from '@/vuex/modules/topic'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        AuthModule,
        CommonModule,
        HouseModule,
        PromotionModule,
        AgencyModule,
        ApartmentModule,
        OrderModule,
        TopicModule
    }
})