import {
    OrderResource
} from '../../api/config'

export default {
    viewOrder: info => {
        return OrderResource().get('/detail', {params: info})
    },
    viewPayed: info => {
        return OrderResource().get('/payedproject', {params: info})
    },
    estimateOrder: info => {
        return OrderResource().get('/estimate', {params: info})
    },
    payOrder: info => {
        return OrderResource().post('/pay', info)
    },
    checkPay: id => {
        return OrderResource().get('/payresult', {params: {check_payment_no: id}})
    },
    subscribeOrder: id => {
        return OrderResource().get('/subscribeqrcode', {params: {order_id: id}})
    },
    cancelSubscribe: info => {
        return OrderResource().delete('/subscribe', {params: info})
    }
}