<template>
    <div class='apartment-traffic'>
        <div style='flex: 1'>
            <div class='apartment-traffic__road'>{{apartment.region}}{{apartment.road}}</div>
            <div class='apartment-traffic__info'>{{apartment.traffic_info}}</div>
        </div>
        <div class='apartment-location' @click='locate'>
            <img src='@/assets/map_location@3x.png' style='height: 32px'/>
            <!-- <br>
            去这里 -->
        </div>
    </div>
</template>

<script>
    export default {
        props: ['apartment'],
        methods: {
            locate () {
                // console.log('???')
                window.open(`https://m.amap.com/navi/?dest=${this.apartment.longitude},${this.apartment.latitude}&key=83893915ca1dc4901543d98604980cc9`)
            }
        }
    }
</script>

<style lang="less" scoped>
    .apartment-traffic {
        padding: 15px 0;
        display: flex;
        align-items: center;
        .apartment-traffic__road {
            font-size: 11pt;
        }
        .apartment-traffic__info {
            font-size: 10pt;
            color: #969799;
        }
        .apartment-location {
            text-align: center;
            font-size: 9pt;
        }
    }
</style>


