<template>
    <div class='layout-title'>
        <div style='flex: 1'>
            <h3>{{layout.name}}</h3>
            <div style='font-size: 11pt;margin: 5px 0'>{{layout.money_label}}</div>
            <div style='font-size: 10pt;color: #969899;'>
                {{layout.layout}}
                ·
                {{layout.square_meter_label}}
                ，
                {{left_number_label}}
            </div>
        </div>
        <div class='layout-title__video' v-if='layout.video_file_ids' @click='play'>
            <img src='@/assets/play_icon@3x.png' style='height: 24px'/>
            <br>
            视频
        </div>
    </div>
</template>

<script>
    export default {
        props: ['layout', 'player'],
        methods: {
            play () {
                this.player.toggle = true
                this.$emit('play')
            }
        }
    }
</script>

<style lang="less" scoped>
    .layout-title {
        display: flex;
        align-items: flex-start;
        .layout-title__video {
            text-align: center;
            font-size: 9pt;
        }
    }
</style>

