export const REQUESTING_ORDER_DETAIL = 'REQUESTING_ORDER_DETAIL'
export const REQUESTING_ORDER_DETAIL_SUCCESS = 'REQUESTING_ORDER_DETAIL_SUCCESS'
export const REQUESTING_ORDER_DETAIL_FAIL = 'REQUESTING_ORDER_DETAIL_FAIL'
export const REQUESTING_PAYED_LIST = 'REQUESTING_PAYED_LIST'
export const REQUESTING_PAYED_LIST_SUCCESS = 'REQUESTING_PAYED_LIST_SUCCESS'
export const REQUESTING_PAYED_LIST_FAIL = 'REQUESTING_PAYED_LIST_FAIL'
export const UPDATING_ORDER_DETAIL = 'UPDATING_ORDER_DETAIL'
export const UPDATING_ORDER_DETAIL_SUCCESS = 'UPDATING_ORDER_DETAIL_SUCCESS'
export const UPDATING_ORDER_DETAIL_FAIL = 'UPDATING_ORDER_DETAIL_FAIL'
export const REQUESTING_ESTIMATE_DETAIL = 'REQUESTING_ESTIMATE_DETAIL'
export const REQUESTING_ESTIMATE_DETAIL_SUCCESS = 'REQUESTING_ESTIMATE_DETAIL_SUCCESS'
export const REQUESTING_ESTIMATE_DETAIL_FAIL = 'REQUESTING_ESTIMATE_DETAIL_FAIL'
export const CLEAN_ESTIMATE = 'CLEAN_ESTIMATE'

