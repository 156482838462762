<template>
    <div>
        <loading/>
    </div>
</template>

<script>
    import signin from '@/mixins/signin'
    import Loading from '@/components/Common/loading'

    export default {
        mixins: [signin],
        components: {
            Loading
        },
        mounted () {
            this.login()
        }
    }
</script>

