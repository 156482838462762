<template>
    <div class='download-app'>
        <img src='@/assets/system_remind@3x.png' class='download-app__logo'/>
        <!-- <h2>房东直租平台</h2> -->
        <p class='context'>请在微信中打开链接</p>
        <div
        :data-clipboard-text='link'
        class='download-app__link'>
            复制链接
        </div>
        <!-- <p class='sub-context'>同时支持个人/商家/合作伙伴</p> -->
    </div>
</template>

<script>
    import tooltip from '@/mixins/tooltip'
    
    export default {
        mixins: [tooltip],
        data () {
            return {
                link: ''
            }
        },
        mounted () {
            const that = this
            this.link = window.location.href
            setTimeout(() => {
                that.copy('.download-app__link')
            }, 300)
        }
    }
</script>

