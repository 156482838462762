<template>
    <div id="app">
        <title>{{$route.meta.title}}</title>
        <div class='weixin-hint' v-if='visible' @click='close'>
            点击右上角，选择“在浏览器中打开”
        </div>
        <router-view v-if='ifLogged || isWechat || $route.name == "app" || $route.name == "order" || $route.name == "feedback"'/>
        <external-link v-else/>
        <photo-swipe/>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import PhotoSwipe from '@/components/Common/photoswipe'
    import ExternalLink from '@/components/Common/link'

    export default {
        components: {
            PhotoSwipe,
            ExternalLink
        },
        computed: {
            ...mapState({
                ifLogged: state => state.AuthModule.ifLogged,
                visible: state => state.CommonModule.visible,
                isWechat: state => state.CommonModule.isWechat
            })
        },
        methods: {
            ...mapActions({
                toggleNotification: 'toggleNotification'
            }),
            close () {
                this.toggleNotification()
            }
        }
    }
</script>

<style lang="less">
    #app {
        font-family: "SF Pro Text","Helvetica Neue","Helvetica","Arial",sans-serif;
        font-weight: 300;
        max-width: 1080px;
        margin: 0 auto;
    }
    .weixin-hint {
        padding: 10px 15px;
        font-size: 10pt;
        background: #08979C;
        position: fixed;
        right: 15px;
        top: 15px;
        color: #fff;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        z-index: 1031;
        &:before {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border: 8px solid;
            border-color: transparent transparent #08979C transparent;
            right: 10px;
            top: -16px;
        }
    }
    .download-app {
        text-align: center;
        padding: 0 15px;
        padding-top: 90px;
        font-size: 10pt;
        color: #333;
        .download-app__logo {
            width: 90px;
            height: 90px;
            border-radius: 2px;
            padding: 30px 0;
        }
        .download-app__btn {
            font-size: 11pt;
            border: 1px solid #ccc;
            border-radius: 2px;
            padding: 10px 30px;
            width: 200px;
            margin: 15px auto;
            margin-top: 60px;
        }
        .download-app__link {
            margin-top: 10px;
            color: #4A90E2;
        }
        .download-notice {
            color: #08979C;
            font-size: 11pt;
            margin-bottom: 10px;
        }
        .context {
            font-size: 12pt;
            margin-top: 10px;
        }
        .sub-context {
            font-size: 10pt;
            .sub-context__link {
                color: #08979C;
            }
        }
    }
    .copy-input {
        box-sizing: border-box;
        opacity: 0;
        width: 0;
        height: 0;
        padding: 0;
        margin: 0;
    }
    .separate-section {
        height: 5px;
        background: #f7f8fa;
        margin: 0 -15px;
    }
    img {
        pointer-events: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }
</style>
