import {
    BINDING_PROMOTION_INFO,
    BINDING_PROMOTION_INFO_SUCCESS,
    BINDING_PROMOTION_INFO_FAIL
} from './_types'

const state = {
    binding: false
}

const mutations = {
    [BINDING_PROMOTION_INFO] (state) {
        state.binding = true
    },
    [BINDING_PROMOTION_INFO_SUCCESS] (state) {
        state.binding = false
    },
    [BINDING_PROMOTION_INFO_FAIL] (state) {
        state.binding = false
    }
}

export default {
    state,
    mutations
}