<template>
    <div class='layout-container'>
        <loading v-if='!detail && requesting'/>
        <div v-if='detail' class='house-detail'>
            <layout-name :layout='detail.apartment.layout' :player='player' v-on:play='generate'/>
            <layout-description :layout='detail.apartment.layout'/>
            <layout-photos :photos='detail.apartment.layout.photos'/>
            <layout-videos :videos='detail.apartment.layout.videos' :player='player'/>
            <apartment-banner :apartment='detail.apartment' v-on:open='jump'/>
        </div>
    </div>
</template>

<script>
    const Swiper = window.Swiper
    import { mapState, mapActions } from 'vuex'
    import tooltip from '@/mixins/tooltip'
    import Loading from '@/components/Common/loading'
    import LayoutName from '@/components/Apartment/layout.name'
    import LayoutDescription from '@/components/Apartment/layout.description'
    import LayoutPhotos from '@/components/Apartment/album'
    import LayoutVideos from '@/components/House/videos'
    import ApartmentBanner from '@/components/Apartment/banner'

    export default {
        mixins: [tooltip],
        components: {
            Loading,
            LayoutName,
            LayoutDescription,
            LayoutPhotos,
            LayoutVideos,
            ApartmentBanner
        },
        data () {
            return {
                player: {
                    playing: 0,
                    toggle: false
                }
            }
        },
        computed: {
            ...mapState({
                requesting: state => state.ApartmentModule.requesting_layout,
                detail: state => state.ApartmentModule.layout
            })
        },
        methods: {
            ...mapActions({
                viewLayoutDetail: 'viewLayoutDetail'
            }),
            update () {
                const that = this
                this.viewLayoutDetail({
                    data: this.$route.params.id,
                })
            },
            generate () {
                const that = this
                setTimeout(() => {
                    const videoSwiper = new Swiper('.video-swiper-container', {
                        speed: 400
                    })
                    videoSwiper.on('slideChange', e => {
                        const id = `video-${this.detail.apartment.layout.videos[that.player.playing].id}`
                        const video = document.getElementById(id)
                        video.pause()
                        that.player.playing = e.activeIndex
                    })
                }, 400)
            },
            jump (message) {
                this.open(`layout/${this.$route.params.id}`, message)
            }
        },
        mounted () {
            this.update()
        }
    }
</script>

<style lang="less">
    .layout-container {
        background: #fff;
        padding: 15px;
        padding-bottom: 83px;
    }
</style>



