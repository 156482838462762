<template>
    <div class="swiper-container">
        <div class="swiper-wrapper">
            <div class='swiper-slide screen-item poster'
            style='background-image: url("https://img.17zub.com/39e28b8f28bbed0119c6d157/2021/08/07/610dfa6e4faf5.png");background-size: auto 50%'
            v-if='detail.room.bed.photos.length <= 0'/>
            <div
            class="swiper-slide screen-item poster"
            v-for='(photo, index) in detail.room.bed.photos'
            :key='photo.id'
            @click='browse(index)'
            :style='{backgroundImage: `url(${photo.large})`}'/>
        </div>
        <div class="swiper-pagination" v-if='detail.room.bed.photos.length > 0'></div>
    </div>
</template>

<script>
    import browser from '@/mixins/browser'

    export default {
        props: ['detail'],
        mixins: [browser],
        methods: {
            browse (index) {
                this.view(index, this.detail.room.bed.photos)
            }
        }
    }
</script>

<style lang="less">
    .screen-item {
        height: 280px !important;
    }
    .swiper-dot {
        display: inline-block;
        background: #fff;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        opacity: .75;
        margin: 3px;
        transition: all ease .2s;
        &-active {
            width: 15px;
            border-radius: 15px;
        }
    }
    .poster {
        background-color: #f2f3f5;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
</style>


