<template>
    <div>
        <div class="topic-gallery">
            <div
            :class="['topic-gallery__item photo', detail.photos.length == 1 ? 'single' : '']"
            v-for="(photo, index) in detail.photos" :key="photo.id"
            :style="{backgroundImage: `url(${photo.medium})`}"
            @click="browse(index)"></div>

            <div
            :class="['topic-gallery__item photo', detail.videos.length == 1 ? 'single' : '']"
            v-for="(video, index) in detail.videos" :key="video.id"
            :style="{backgroundImage: `url(${video.screenshot})`}"
            @click="play(index)">
                <img src='@/assets/play-big@3x.png' class="player"/>
            </div>
        </div>
        <house-videos :videos='detail.videos' :player='player' v-if='detail.videos'/>
    </div>
</template>

<script>
    const Swiper = window.Swiper
    import browser from '@/mixins/browser'
    import HouseVideos from '@/components/House/videos'

    export default {
        props: ['detail'],
        components: {
            HouseVideos
        },
        mixins: [browser],
        data () {
            return {
                player: {
                    playing: 0,
                    toggle: false
                }
            }
        },
        methods: {
            generate () {
                const that = this
                setTimeout(() => {
                    const videoSwiper = new Swiper('.video-swiper-container', {
                        speed: 400
                    })
                    videoSwiper.on('slideChange', e => {
                        const id = `video-${this.detail.videos[that.player.playing].id}`
                        const video = document.getElementById(id)
                        video.pause()
                        that.player.playing = e.activeIndex
                    })
                }, 400)
            },
            play (index) {
                this.player.playing = index
                this.player.toggle = true
                this.generate()
                // this.$emit('play')
            },
            browse (index) {
                this.view(index, this.detail.photos)
            }
        }
    }
</script>

<style lang="less" scoped>
    .topic-gallery {
        overflow: hidden;
        .topic-gallery__item {
            width: calc((100% - 10px)/3);
            padding-top: calc((100% - 10px)/3);
            height: 0;
            float: left;
            margin-right: 5px;
            margin-top: 5px;
            position: relative;
            &:nth-child(3n + 3) {
                margin-right: 0;
            }
            &.single {
                width: 200px;
                height: 130px;
                padding-top: 0;
            }
            .player {
                position: absolute;
                width: 32px;
                height: 32px;
                top: calc((100% - 32px)/2);
                left: calc((100% - 32px)/2);
            }
        }
    }
</style>
