<template>
    <div>
        <div class='apartment-title'>
            <div class='apartment-title__bar'>
                <h3 style='flex: 1'>{{apartment.title}}</h3>
                <!-- <div style='font-size: 10pt;opacity: .7'>
                    介绍
                    <img
                    src='@/assets/icon_right_li_s@3x.png'
                    style='height: 9pt;position: relative;top:1px'/>
                </div> -->
            </div>
            <div class='tag-list'>
                <span v-for='(tag, index) in apartment.tags.split(",")' :key='`tag-${index}`' class='tag-list__item'>
                    {{tag}}
                </span>
            </div>
        </div>

        <intro-drawer :toggle='desc' :title='"介绍"' v-on:close='close'>
            <p style='padding: 10px; font-size: 11pt;'>{{apartment.description}}</p>
        </intro-drawer>
    </div>
</template>

<script>
    import IntroDrawer from './drawer'

    export default {
        props: ['apartment'],
        components: {
            IntroDrawer
        },
        data () {
            return {
                desc: false
            }
        },
        methods: {
            intro () {
                this.desc = true
            },
            close () {
                this.desc = false
            }
        }
    }
</script>

<style lang="less" scoped>
    .apartment-title {
        margin-top: 15px;
        border-bottom: 1px solid #eee;
        padding-bottom: 15px;
        .apartment-title__bar {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .tag-list {
        .tag-list__item {
            font-size: 10pt;
            color: #646566;
            background: #f7f8fa;
            display: inline-block;
            margin-right: 5px;
            margin-top: 5px;
            padding: 2px 4px;
            border-radius: 2px;
        }
    }
</style>


