import {
    REQUESTING_HOUSE_DETAIL,
    REQUESTING_HOUSE_DETAIL_SUCCESS,
    REQUESTING_HOUSE_DETAIL_FAIL,
    UPDATING_HOUSE_DETAIL,
    UPDATING_HOUSE_DETAIL_SUCCESS,
    UPDATING_HOUSE_DETAIL_FAIL
} from './_types'

const state = {
    requesting: false,
    detail: null,
    unexistance: false,
    updating: false
}

const mutations = {
    [REQUESTING_HOUSE_DETAIL] (state) {
        state.requesting = true
        state.unexistance = false
    },
    [REQUESTING_HOUSE_DETAIL_SUCCESS] (state, action) {
        state.detail = action.result.sale ? {
            ...action.result,
            room: {...action.result.sale, bed: action.result.sale},
        } : action.result
        state.requesting = false
        state.unexistance = false
    },
    [REQUESTING_HOUSE_DETAIL_FAIL] (state) {
        state.detail = null
        state.requesting = false
        state.unexistance = true
    },
    [UPDATING_HOUSE_DETAIL] (state) {
        state.updating = true
    },
    [UPDATING_HOUSE_DETAIL_SUCCESS] (state) {
        state.updating = false
    },
    [UPDATING_HOUSE_DETAIL_FAIL] (state) {
        state.updating = false
    }
}

export default {
    state,
    mutations
}