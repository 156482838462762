<template>
    <div>
        <div class='title-section'>
            <div class="house-price" v-if='$route.name == "house"'>
                <span class="house-price__money">
                    {{detail.room.bed.detail_money_label}}
                </span>
                <span class="house-price__tenancy" v-if='detail.room.bed.tenancy'>（{{detail.room.bed.tenancy}}个月起租价）</span>
            </div>
            <div class="house-price" v-else>
                <span class="house-price__money">
                    {{detail.room.bed.detail_money_label}}</span>
            </div>

            <div class='house-info__icon'>
                <img
                class='house-info__btn'
                src='@/assets/housedetail_btn_shooting_normal@3x.png'
                @click='jump'
                v-if="detail.room.bed.has_propect"/>

                <img
                class='house-info__btn'
                src='@/assets/housedetail_btn_link_normal@3x.png'
                @click='jump'
                v-if='detail.room.bed.links && detail.room.bed.links.length > 0'/>

                <img
                src='@/assets/housedetail_btn_video_normal@3x.png'
                class='house-info__btn'
                v-if='detail.room.bed.has_video'
                @click='play'/>
            </div>
        </div>
        <div class='house-status'>
            <div class='house-status__time'>
                <img
                class='house-status__icon'
                src='@/assets/shotting_icon_time@3x.png'/>
                {{detail.maintain_time_label}}
            </div>
            <!-- <div class='house-status__external'>
                历史
                <img src='@/assets/book_icon_next@3x.png' class='house-status__link_icon'/>
            </div> -->
        </div>
        <!-- <div class='house-status' v-if='detail.is_agent'>
            <div class='house-status__time'>
                <img
                class='house-status__icon'
                src='@/assets/house_icon_dlsmy@3x.png'/>
                平台经纪人提供带看和签约服务
            </div>
            <div class='house-status__external' v-if='detail.webpage.agent_service_instruction' @click='service'>
                详情
                <img src='@/assets/book_icon_next@3x.png' class='house-status__link_icon'/>
            </div>
        </div> -->
    </div>
</template>

<script>
    export default {
        props: ['detail', 'player'],
        methods: {
            play () {
                this.player.toggle = true
                this.$emit('play')
            },
            service () {
                window.open(this.detail.webpage.agent_service_instruction)
            },
            jump () {
                this.$emit('open', '在应用查看完整房源信息')
            }
        }
    }
</script>

<style lang='less' scoped>
    .house-info__icon {
        height: 48px;
        display: flex;
        align-items: center;
        .house-info__btn {
            height: 48px;
        }
    }
    .title-section {
        height: 60px;
        padding: 0 15px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f8f8f8;
    }
    .house-price__text {
        font-size: 10pt;
    }
    .house-price__tenancy {
        font-size: 9pt;
        color: #999;
    }
    .house-price__money {
        font-size: 14pt;
        font-weight: 400;
    }
    .house-status {
        background: #fff;
        padding: 10px 15px;
        font-size: 10pt;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .house-status__time {
            display: flex;
            flex: 1;
            align-items: center;
        }
        .house-status__icon {
            width: 14px;
            height: 14px;
            position: relative;
            top: -1px;
            margin-right: 5px;
        }
        .house-status__external {
            font-size: 9pt;
            display: flex;
            align-items: center;
            width: 35px;
            .house-status__link_icon {
                height: 10px;
                margin-left: 5px;
            }
        }
    }
</style>

