<template>
    <div @click="download" class="topic-guide">
        点击下载App，查看评论
    </div>
</template>

<script>
    export default {
        methods: {
            download () {
                this.$router.push({name: 'app', query: {target: `topic/${this.$route.params.id}`}})
            }
        }
    }
</script>

<style lang="less" scoped>
    .topic-guide {
        text-align: center;
        padding: 15px;
        color: #08979c;
    }
</style>
