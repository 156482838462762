<template>
    <div class='gallery-list'>
        <div
        v-for='(photo, index) in photos'
        :key='photo.id'
        @click='view(index, photos)'
        :style='{backgroundImage: `url(${photo.medium})`}'
        class='gallery-list__item'/>
    </div>
</template>

<script>
    import browser from '@/mixins/browser'

    export default {
        props: ['photos'],
        mixins: [browser]
    }
</script>

<style lang="less" scoped>
    .gallery-list {
        .gallery-list__item {
            height: 240px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-bottom: 5px;
            border-radius: 2px;
        }
    }
</style>


