import api from './_api'
import * as types from './_types'
import { ActionBuilder } from '@/vuex/api/builder'

const bindPromotionInfo = ActionBuilder({
    method: api.bindPromotion,
    actionStart: types.BINDING_PROMOTION_INFO,
    actionSuccess: types.BINDING_PROMOTION_INFO_SUCCESS,
    actionFail: types.BINDING_PROMOTION_INFO_FAIL
})

export default {
    actions: {
        bindPromotionInfo
    }
}