import md5 from '../utils/md5'

const SCENE = '2567a5ec9705eb7ac2c984033e06189d'

const timeStamp = () => Math.round(new Date().getTime() / 1000)

export const ticket =  (token) => {
    let _timestamp = timeStamp()
    let _oauth = token.getValue('token') ? token.getValue('token') : md5(_timestamp)
    let _signature = md5(_oauth + _timestamp)
    
    return `timestamp=${_timestamp};oauth2=${_oauth};signature=${_signature}`
}

export default class Signature {
    _scene
    _version
    _key
    token
    constructor (token, version, deployKey) {
        this.token = token
        this._scene = SCENE
        this._version = version
        this._key = deployKey ? deployKey : ''
    }
    generate (route, method, params) {
        let _timestamp = timeStamp()
        let _secret = this.token.getValue('secret')
        let _oauth = this.token.getValue('token') ? this.token.getValue('token') : md5(_timestamp)
        let _method = method ? method.toLowerCase() : 'get'
        let _params = (_method === 'get' || _method === 'delete') ? '{}' : JSON.stringify(params)
        let _url = this._version ? this._version + route : route.substring(1)
        let _source = `request_url=${_url}&content=${_params}&request_method=${_method}&timestamp=${_timestamp}&secret=${_secret ? _secret : ''}`
        let _signature = md5(_source)
        
        return `timestamp=${_timestamp};oauth2=${_oauth};signature=${_signature};scene=${this._scene};deployKey=${this._key}`
    }
}