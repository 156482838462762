import {
    AuthResource,
    CommonResource,
    UserResource
} from '@/vuex/api/config'

export default {
    getAuth: info => {
        return AuthResource().post('/oauth', {...info, zhizu: 1})
    },
    getProfile: () => {
        return CommonResource().get('/user')
    },
    getCode: () => {
        return UserResource().get('/code')
    },
    getOpenId: info => {
        return AuthResource('agent').get('/openid', {params: info})
    }
}