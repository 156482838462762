<template>
    <div class='apartment-banner'>
        <img src='@/assets/60pt@3x.png' class='apartment-banner__icon'>
        <div class='apartment-banner__context'>真实房源<br>实拍照片</div>
        <div class='apartment-banner__btns'>
            <div @click='call' class='apartment-banner_tel' v-if='apartment.has_contact_phone'>
                <img src='@/assets/telphone@3x.png' style='height: 32px;margin-right: 5px;margin-top: 5px'/>
            </div>
            <div class='apartment-banner__btn primary' @click='download'>下载应用</div>
            <wx-open-launch-app
            id='launch-btn' 
            appid='wx87516476d1b96c8d'
            :extinfo='`${$route.name}:${$route.params.id}`'
            @error='fail'
            style='display: inline-block;border:none'>
                <script type="text/wxtag-template">
                    <style>
                        .apartment-banner__btn {
                            font-family: "SF Pro Text","Helvetica Neue","Helvetica","Arial",sans-serif;
                            font-weight: 300;
                            box-sizing: border-box;
                            border: 1px solid #eee;
                            font-size: 10pt;
                            height: 36px;
                            width: 74px;
                            text-align: center;
                            line-height: 36px;
                            border-radius: 4px;
                        }
                    </style>
                    <div class='apartment-banner__btn'>立即打开</div>
                </script>
            </wx-open-launch-app>
        </div>
    </div>
</template>

<script>
    const wx = window.wx
    import { mapActions } from 'vuex'

    export default {
        props: ['apartment'],
        methods: {
            ...mapActions({
                getJsTicket: 'getJsTicket',
                clickApartmentPhone: 'clickApartmentPhone'
            }),
            download () {
                this.$router.push({name: 'app', query: {target: `${this.$route.name}/${this.$route.params.id}`}})
            },
            jump () {
                this.$emit('open')
            },
            fail (){
                // window.alert(`${e.detail.errMsg}`)
                this.jump()
            },
            call () {
                this.clickApartmentPhone({
                    data: {
                        apartment_id: this.apartment.id,
                        layout_id: this.$route.name == 'layout' ? this.apartment.layout.id : ''
                    },
                    success: res => {
                        setTimeout(() => {
                            window.open(`tel://${res}`)
                        }, 100)
                    }
                })
            }
        },
        mounted () {
            this.getJsTicket({
                data: `${window.location.protocol}//${window.location.host}/${this.$route.name}/${this.$route.params.id}`,
                success: res => {
                    // console.log(res)
                    wx.config({
                        debug: false,
                        ...res,
                        jsApiList: ['onMenuShareAppMessage'],
                        openTagList: ['wx-open-launch-app']
                    })
                }
            })
        }
    }
</script>


<style lang="less">
    .apartment-banner {
        background: #fff;
        padding: 10px 15px;
        padding-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1px solid #eee;
        .apartment-banner_tel {
            text-align: center;
            font-size: 9pt;
            margin-right: 15px;
        }
        .apartment-banner__context {
            font-size: 10pt;
            flex: 1;
            text-align: left;
        }
        .apartment-banner__icon {
            height: 36px;
            width: 36px;
            border-radius: 4px;
            margin-right: 10px;
        }
        .apartment-banner__btns {
            display: flex;
            align-items: center;
            .apartment-banner__btn {
                box-sizing: border-box;
                border: 1px solid #eee;
                font-size: 10pt;
                height: 36px;
                width: 74px;
                text-align: center;
                line-height: 36px;
                border-radius: 4px;
                margin-right: 10px;
                &.primary {
                    background: #08979C;
                    color: #fff;
                    border-color: #08979C;
                }
            }
        }
    }
</style>
