import {
    ViewResource,
    FollowResource
} from '../../api/config'

export default {
    viewHouse: info => {
        return info.sale_id
        ? ViewResource().get('/sale', {params: info})
        : ViewResource('v4').get('/bed', {params: info})
    },
    collectHouse: info => {
        if (info.route == 'sale') {
            return info.follow
            ? FollowResource().delete('/sale', {params: {sale_id: info.id}})
            : FollowResource().post('/sale', {sale_id: info.id})
        } else {
            return info.follow
            ? FollowResource().delete('/bed', {params: {bed_id: info.id}})
            : FollowResource().post('/bed', {bed_id: info.id, source_uid: info.source_uid})
        }
    }
}