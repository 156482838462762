export const ActionBuilder = options => {
    const fetch = ({commit}, params) => {
        if (options.actionStart) commit(options.actionStart, params ? params.data : {})
        // if has method, excute
        if (options.method) {
            options.method(params ? params.data : {}).then(res => {
                let result = res.data ? res.data.result : res
                commit(options.actionSuccess, {
                    result: result
                })
                if (params && params.success) params.success(result)
            }).catch (e => {
                    // window.alert(e)
                if (options.actionFail) commit(options.actionFail, e)
                if (params && params.fail) params.fail(e)
            })
        } else {
            commit(options.actionSuccess, params ? params.data : {})
            if (params && params.success) params.success()
        }
    }
    return fetch
}