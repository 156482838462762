import api from './_api'
import * as types from './_types'
import { ActionBuilder } from '@/vuex/api/builder'

const viewOrderDetail = ActionBuilder({
    method: api.viewOrder,
    actionStart: types.REQUESTING_ORDER_DETAIL,
    actionSuccess: types.REQUESTING_ORDER_DETAIL_SUCCESS,
    actionFail: types.REQUESTING_ORDER_DETAIL_FAIL
})

const viewPayedList = ActionBuilder({
    method: api.viewPayed,
    actionStart: types.REQUESTING_PAYED_LIST,
    actionSuccess: types.REQUESTING_PAYED_LIST_SUCCESS,
    actionFail: types.REQUESTING_PAYED_LIST_FAIL
})

const estimateOrderAmount = ActionBuilder({
    method: api.estimateOrder,
    actionStart: types.REQUESTING_ESTIMATE_DETAIL,
    actionSuccess: types.REQUESTING_ESTIMATE_DETAIL_SUCCESS,
    actionFail: types.REQUESTING_ESTIMATE_DETAIL_FAIL
})

const payOrderChannel = ActionBuilder({
    method: api.payOrder,
    actionStart: types.UPDATING_ORDER_DETAIL,
    actionSuccess: types.UPDATING_ORDER_DETAIL_SUCCESS,
    actionFail: types.UPDATING_ORDER_DETAIL_FAIL
})

const checkPayResult = ActionBuilder({
    method: api.checkPay,
    actionStart: types.UPDATING_ORDER_DETAIL,
    actionSuccess: types.UPDATING_ORDER_DETAIL_SUCCESS,
    actionFail: types.UPDATING_ORDER_DETAIL_FAIL
})

const subscribeOrderMessage = ActionBuilder({
    method: api.subscribeOrder,
    actionStart: types.UPDATING_ORDER_DETAIL,
    actionSuccess: types.UPDATING_ORDER_DETAIL_SUCCESS,
    actionFail: types.UPDATING_ORDER_DETAIL_FAIL
})

const cancelSubscribeOrder = ActionBuilder({
    method: api.cancelSubscribe,
    actionStart: types.UPDATING_ORDER_DETAIL,
    actionSuccess: types.UPDATING_ORDER_DETAIL_SUCCESS,
    actionFail: types.UPDATING_ORDER_DETAIL_FAIL
})

const cleanEstimate = ActionBuilder({
    actionSuccess: types.CLEAN_ESTIMATE
})

export default {
    actions: {
        viewOrderDetail,
        viewPayedList,
        estimateOrderAmount,
        cleanEstimate,
        payOrderChannel,
        checkPayResult,
        subscribeOrderMessage,
        cancelSubscribeOrder
    }
}