<template>
    <div class='panel-wrapper'>
        <transition name='slide' mode='out-in'>
            <div class='panel-container' v-if='toggle'>
                <img src='@/assets/close@3x.png'
                class='panel-container__close'
                @click.stop='close'/>
                <slot/>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        props: ['toggle'],
        methods: {
            close () {
                this.$emit('close')
            }
        }
    }
</script>

<style lang='less' scoped>
    .panel-container {
        position: fixed;
        background: #000;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
        padding-top: 45px;
        padding-bottom: 90px;
        box-sizing: border-box;
        .panel-container__close {
            position: absolute;
            left: 0px;
            top: 0px;
            padding: 15px;
            width: 16px;
            height: 16px;
            z-index: 100;
        }
    }
    .slide-enter-active, .slide-leave-active {
        transition: all ease .3s;
        opacity: 1;
    }
    .slide-enter, .slide-leave-to {
        opacity: 0;
        transform: translateY(100%);
    }
</style>


