import Vue from 'vue'
import VueRouter from 'vue-router'
import { LOGIN_URL, token } from '@/vuex/api/config'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'app',
        component: () => import('../views/Download/index.vue'),
        meta: {
            title: '下载应用'
        }
    },
    {
        path: '/house/:id',
        name: 'house',
        component: () => import('../views/House/index.vue'),
        meta: {
            title: '房源详情'
        }
    },
    {
        path: '/sale/:id',
        name: 'sale',
        component: () => import('../views/House/index.vue'),
        meta: {
            title: '房源详情'
        }
    },
    {
        path: '/apartment/:id',
        name: 'apartment',
        component: () => import('../views/Apartment/index.vue'),
        meta: {
            title: '公寓详情'
        }
    },
    {
        path: '/layout/:id',
        name: 'layout',
        component: () => import('../views/Apartment/layout.vue'),
        meta: {
            title: '房型详情'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login/index.vue'),
        meta: {
            title: '登录中...'
        }
    },
    {
        path: '/jump/:source/:sid/:id',
        name: 'jump',
        component: () => import('../views/Transit/index.vue'),
        meta: {
            title: '跳转中...'
        }
    },
    {
        path: '/t/:source/:sid/:id',
        name: 'transit',
        component: () => import('../views/Transit/index.vue'),
        meta: {
            title: '跳转中...'
        }
    },
    {
        path: '/code',
        name: 'code',
        component: () => import('../views/Code/index.vue'),
        meta: {
            title: '用户个人码'
        }
    },
    {
        path: '/statistic',
        name: 'statistic',
        component: () => import('../views/Statistic/index.vue'),
        meta: {
            title: '数据统计'
        }
    },
    {
        path: '/order/:id',
        name: 'order',
        component: () => import('../views/Order/index.vue'),
        meta: {
            title: '支付订单'
        }
    },
    {
        path: '/feedback/:id',
        name: 'feedback',
        component: () => import('../views/Order/success.vue'),
        meta: {
            title: '支付完成'
        }
    },
    {
        path: '/topic/:id',
        name: 'topic',
        component: () => import('../views/Topic/index.vue'),
        meta: {
            title: '详情'
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const ifWechat = /MicroMessenger/i.test(window.navigator.userAgent)
    const ifLogged = token.getToken('uid') && true
    const login_host = `${LOGIN_URL}?m=1`
    const login_url = `${window.location.protocol}//${window.location.host}/login`
    if (to.name !== 'login') token.setValue('target', to.fullPath)
    if (ifLogged) {
        next()
    } else {
        if (ifWechat) {
            // console.log(to)
            if (ifLogged || to.name == 'app' || to.name == 'order' || to.name == 'feedback') {
                // if iflogged, next
                next()
            } else {
                if (to.name != 'login') {
                    // console.log(to.fullPath)
                    // token.setValue('target', to.fullPath)
                    window.location.href = `${login_host}&goto=${encodeURIComponent(login_url)}`
                } else {
                    next()
                }
            }
        } else {
            if (to.name == 'house') {
                next({name: 'app', query: {target: `bed/${to.params.id}`}})
            } else if (to.name == 'apartment' || to.name == 'layout' || to.name == 'topic' || to.name == 'sale') {
                next({name: 'app', query: {target: `${to.name}/${to.params.id}`}})
            } else {
                next()
            }
        }
    }
})

export default router
