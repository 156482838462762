import {
    ViewResource
} from '../../api/config'

export default {
    viewApartment: id => {
        return ViewResource().get('/apartment', {params: {apartment_id: id}})
    },
    viewLayout: id => {
        return ViewResource().get('/layout', {params: {layout_id: id}})
    },
    checkPhone: info => {
        return ViewResource().post('/clickphone', info)
    }
}