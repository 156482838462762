<template>
    <div class='container'>
        <loading v-if='generating'/>
        <div class='user-code' v-if='!generating && identity_code'>
            <div class='user-code__title'>我的用户码</div>
            <div class='user-code__number'>{{identity_code.code}}</div>
            <!-- <div class='user-codes'>
                <div v-for="(code, index) in identity_code.code.split('')" :key='index' class='user-codes__item'>
                    {{code}}
                </div>
            </div> -->
            <div class='user-code__copy' :data-clipboard-text='identity_code.code'>点击复制用户码</div>
            <div class='user-code__time'>（今日有效）</div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import tooltip from '@/mixins/tooltip'
    import Loading from '@/components/Common/loading'
    
    export default {
        mixins: [tooltip],
        components: {
            Loading
        },
        computed: {
            ...mapState({
                generating: state => state.AuthModule.generating,
                identity_code: state => state.AuthModule.identity_code
            })
        },
        methods: {
            ...mapActions({
                getUserCode: 'getUserCode'
            })
        },
        mounted () {
            const that = this
            this.getUserCode({
                success: () => {
                    that.copy('.user-code__copy')
                }
            })
        }
    }
</script>

<style lang="less" scoped>
    .user-code {
        text-align: center;
        padding-top: 40px;
        .user-code__title {
            font-weight: 450;
        }
        .user-codes {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 30px;
            .user-codes__item {
                background: #f7f8fa;
                font-size: 22pt;
                font-weight: 450;
                border-radius: 4px;
                width: 45px;
                height: 55px;
                line-height: 55px;
            }
        }
        .user-code__number {
            background: #f7f8fa;
            text-align: center;
            margin: 30px auto;
            width: 70%;
            height: 55px;
            font-size: 22pt;
            font-weight: 450;
            border-radius: 4px;
            line-height: 55px;
            letter-spacing: 15px;
        }
        .user-code__copy {
            font-size: 11pt;
            color: #576B95;
        }
        .user-code__time {
            margin-top: 15px;
            font-size: 11pt;
            color: #999;
        }
    }
</style>


