import { mapState, mapActions } from 'vuex'
import ClipboardJS from 'clipboard'
const weui = window.weui

export default {
    computed: {
        ...mapState({
            isWechat: state => state.CommonModule.isWechat
        })
    },
    methods: {
        ...mapActions({
            toggleNotification: 'toggleNotification'
        }),
        copy (btn) {
            const clipboard = new ClipboardJS(btn)

            clipboard.on('success', () => {
                weui.toast('已复制')
            })
        },
        open (target, redirect) {
            const that = this
            if (redirect) {
                if (that.$route.name != 'app') that.$router.push({name: 'app', query: {target: target, redirect: redirect}})
            } else if (this.isWechat) {
                this.toggleNotification()
            } else {
                window.open(target ? `zuber://${target}` : `zuber://app`)
                setTimeout(() => {
                    if (that.$route.name != 'app') that.$router.push({name: 'app', query: {target: target}})
                }, 2000)
            }
        },
    }
}