export const GET_USER_AUTH = 'GET_USER_AUTH'
export const GET_USER_AUTH_SUCCESS = 'GET_USER_AUTH_SUCCESS'
export const GET_USER_AUTH_FAIL = 'GET_USER_AUTH_FAIL'
export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL'
export const GET_USER_CODE = 'GET_USER_CODE'
export const GET_USER_CODE_SUCCESS = 'GET_USER_CODE_SUCCESS'
export const GET_USER_CODE_FAIL = 'GET_USER_CODE_FAIL'
export const GET_OPEN_ID = 'GET_OPEN_ID'
export const GET_OPEN_ID_SUCCESS = 'GET_OPEN_ID_SUCCESS'
export const GET_OPEN_ID_FAIL = 'GET_OPEN_ID_FAIL'
