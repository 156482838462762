import { mapState, mapActions } from 'vuex'

export default {
    computed: {
        ...mapState({
            logging: state => state.AuthModule.logging,
            target: state => state.CommonModule.target,
            profile: state => state.AuthModule.profile
        })
    },
    methods: {
        ...mapActions({
            getUserAuth: 'getUserAuth'
        }),
        login () {
            const that = this
            if (!this.logging) {
                this.getUserAuth({
                    data: this.$route.query,
                    success: () => {
                        // that.$router.replace({path: that.target})
                        window.location.href = that.target
                    },
                    fail: () => {
                        that.$router.replace({name: 'app'})
                    }
                })
            }
        }
    }
}