<template>
    <div>
        <div class='house-info' @click='locate'>
            <div class='house-info__label'>
                房屋地址：
            </div>
            <div class='house-info__content'>
                {{detail.room.format_address_title}}
                <!-- <span v-if='detail.room.road'>{{detail.room.road}}</span>
                <span v-if="detail.room.road && detail.room.department">(</span>
                {{detail.room.department}}
                <span v-if="detail.room.road && detail.room.department">)</span> -->
            </div>
            <div class='house-info__afterfix'>
                <!-- <div class='house-info__link' @click="department">
                    <span>小区</span>
                    <img src='@/assets/book_icon_next@3x.png' class='house-info__link_icon'/>
                </div> -->
                <div class='house-info__link'>
                    <img src='@/assets/map_location@3x.png' class='house-info__link_icon'/>
                    <!-- <span>地图</span>
                    <img src='@/assets/book_icon_next@3x.png' class='house-info__link_icon'/> -->
                </div>
            </div>
        </div>

        <div class='house-info'>
            <div class='house-info__label'>
                交通信息：
            </div>
            <div class='house-info__content'>
                <div
                v-for="(subway, index) in detail.room.subways"
                :key="index"
                class="traffic-item"
                v-show="toggle || index == 0"
                @click="full">
                    <div class="traffic-item__station">
                        {{subway.station}}<span v-if="subway.line_text">({{subway.line_text}})</span>

                        <div v-if="index == 0 && detail.room.subways.length > 1">
                            <img
                            src="@/assets/icon_right_li_s@3x.png"
                            class="traffic-item__icon"
                            v-if='!toggle'/>
                            <img
                            src="@/assets/icon_down_li_s@3x.png"
                            class="traffic-item__icon"
                            v-else/>
                        </div>
                    </div>
                    <div class="traffic-item__duration" v-if="subway.duration_text">
                        {{subway.duration_text}} {{subway.distance_text}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['detail'],
        data () {
            return {
                toggle: false
            }
        },
        methods: {
            full () {
                this.toggle = !this.toggle
            },
            department () {
                window.open(this.detail.room.department_url)
            },
            locate () {
                window.open(`https://m.amap.com/navi/?dest=${this.detail.room.longitude},${this.detail.room.latitude}&key=83893915ca1dc4901543d98604980cc9`)
            }
        }
    }
</script>

<style lang="less" scoped>
    .traffic-item {
        margin-top: 15px;
        &:first-child {
            margin-top: 0;
        }
        .traffic-item__station {
            display: flex;
            .traffic-item__icon {
                position: relative;
                margin-left: 5px;
                width: 12px;
                height: 9px;
            }
        }
        .traffic-item__duration {
            color: #bbb;
            font-size: 9pt;
            margin-top: 0px;
        }
    }
    .house-info__afterfix {
        width: 90px;
        display: flex;
        justify-content: flex-end;
        font-size: 9pt;
        .house-info__link {
            .house-info__link_icon {
                height: 18px;
                margin-left: 5px;
            }
        }
    }
</style>

