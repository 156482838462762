<template>
    <div class='container'>
        <loading v-if='!detail && requesting'/>
        <div v-if='detail' class='order-detail'>
            <div class="order-section">
                <div class="order-section__title">
                    <img src='@/assets/button_back@3x.png' class='order-section__cancel' v-if="tab != 'order'" @click='cancel'/>
                    {{tab == 'order' ? '订单详情' : tab == 'payed' ? '已付款项' : '收银台'}}
                    <div class="order-section__tag" @click="payed" v-if="tab == 'order'">已付款项</div>
                </div>
            </div>

            <transition name="fade" mode="out-in">
                <div v-if="tab == 'order'" key="order'">
                    <order-info :detail='detail'/>
                    <div class="order-section__separater">
                        <div>待付款项</div>
                        <subscribe-order :detail="detail" :openid="openid" v-if="isWechat" v-on:update="update"/>
                    </div>
                    <order-list :detail='detail' :selected='selected' v-on:estimate='estimate'/>
                    <div class="order-section__hint" v-if="detail.next_project && detail.next_project.payment_amount">
                        下期租金：{{detail.next_project.create_date}}，{{detail.next_project.payment_amount}}元
                    </div>

                    <div class="order-bar">
                        <div class="order-bar__amount">
                            <span style="font-size: 10pt">合计：</span>
                            <span style="color: #EE0A24;font-size: 14pt;font-weight: 400">
                                ￥{{payment ? payment.common_order.payment_amount : '-'}}
                            </span>
                        </div>
                        <div class="order-bar__confirm" @click="pay">支 付</div>
                    </div>
                </div>
                <div v-if="tab == 'payed'" key="payed">
                    <order-payed :list='payed_list'/>
                </div>
                <div v-if="tab == 'casher'" key="casher">
                    <div class="order-section">
                        <div class="order-section__item">
                            <div class="order-section__content" style="text-align: center;color: #08979C;">
                                <div>
                                    ￥
                                    <span  style="font-size: 24pt;font-weight: 400">
                                        {{payment.common_order.payment_amount}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="order-section__separater">请选择渠道进行支付：</div>
                    <order-channel :channels='payment.estimate' :channel='channel'/>
                    <div class="order-section__pay" @click="confirm">确认支付{{channel.selected.total_amount}}元</div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    const weui = window.weui
    import { mapState, mapActions } from 'vuex'
    import authorize from '@/mixins/authorize'
    import Loading from '@/components/Common/loading'
    import OrderInfo from '@/components/Order/info'
    import OrderList from '@/components/Order/check'
    import OrderPayed from '@/components/Order/list'
    import OrderChannel from '@/components/Order/channel'
    import SubscribeOrder from '@/components/Order/subscribe'

    export default {
        mixins: [authorize],
        components: {
            Loading,
            OrderInfo,
            OrderList,
            OrderPayed,
            OrderChannel,
            SubscribeOrder
        },
        data () {
            return {
                tab: 'order',
                selected: [],
                channel: { selected: null },
                paying: false
            }
        },
        computed: {
            ...mapState({
                openid: state => state.AuthModule.current_openid,
                isWechat: state => state.CommonModule.isWechat,
                isAlipay: state => state.CommonModule.isAlipay,
                requesting: state => state.OrderModule.requesting,
                detail: state => state.OrderModule.detail,
                updating: state => state.OrderModule.updating,
                payed_list: state => state.OrderModule.payed_list,
                payment: state => state.OrderModule.payment
            })
        },
        methods: {
            ...mapActions({
                viewOrderDetail: 'viewOrderDetail',
                viewPayedList: 'viewPayedList',
                estimateOrderAmount: 'estimateOrderAmount',
                payOrderChannel: 'payOrderChannel'
            }),
            redirect () {
                const uri = encodeURIComponent(window.location.href)
                const target = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx18a7b7cc81d82143&redirect_uri=${uri}&response_type=code&scope=snsapi_base&state=${new Date().getTime()}#wechat_redirect`
                // console.log(target)
                window.location.href = target
            },
            update () {
                const that = this
                this.viewOrderDetail({
                    data: {
                        order_no: this.$route.params.id,
                        openid: this.openid
                    },
                    success: () => {
                        that.init()
                    }
                })
            },
            init () {
                const that = this
                this.tab = 'order'
                this.selected = []
                if (this.detail.unpayed_projects.length > 0) {
                    if (this.detail.unpayed_projects[0].type == 1) {
                        this.selected.push(this.detail.unpayed_projects[0].id)
                    } else {
                        this.detail.unpayed_projects.forEach(item => {
                            that.selected.push(item.id)
                        })
                    }
                    this.estimate()
                }
            },
            estimate () {
                this.estimateOrderAmount({
                    data: {
                        order_id: this.detail.id,
                        project_ids: this.selected.join(',')
                    }
                })
            },
            payed () {
                this.tab = 'payed'
                this.viewPayedList({
                    data: {
                        order_id: this.detail.id,
                        page: 1
                    }
                })
            },
            pay () {
                if (this.payment) {
                    this.channel.selected = this.payment.estimate[0]
                    this.tab = 'casher'
                } else {
                    weui.topTips('请先选择待付款项', 1000)
                }
            },
            cancel () {
                const that = this
                if (this.tab == 'casher') {
                    weui.confirm('放弃支付？', {
                        buttons: [{
                            label: '放弃',
                            type: 'default',
                            onClick: () => {
                                that.tab = 'order'
                            }
                        },{
                            label: '继续支付',
                            type: 'primary'
                        }]
                    })
                } else {
                    this.tab = 'order'
                }
            },
            confirm () {
                const that = this
                if (!this.paying && this.tab == 'casher') {
                    this.paying = true
                    const loader = weui.loading('')
                    this.payOrderChannel({
                        data: {
                            channel: this.channel.selected.channel,
                            pay_order_no: this.payment.common_order.pay_order_no,
                            sub_channel: this.isWechat ? 'weixin' : (this.isAlipay) ? 'alipay' : ''
                        },
                        success: res => {
                            loader.hide()
                            window.location.href = res.url
                            that.paying = false
                            weui.alert('跳转至支付页面', {
                                buttons: [{
                                    label: '返回',
                                    type: 'primary',
                                    onClick: () => {
                                        that.update()
                                    }
                                }]
                            })
                            // window.open(res.url)
                        },
                        fail: () => {
                            loader.hide()
                            that.paying = false
                        }
                    })
                }
            }
        },
        mounted () {
            // console.info(this.isWechat, !this.$route.query.code)
            if (this.isWechat) {
                // 若本地已有openid直接取订单
                if (this.openid) {
                    this.update()
                } else {
                    // 若本地没有openid

                    // 若网址有code和state，则发送授权接口获取openid
                    if (this.$route.query.code) {
                        this.auth(this.update)
                    } else {
                        // 若网址也无code，则需要前往跳转获取
                        this.redirect()
                    }
                }
            } else {
                this.update()
            }
        }
    }
</script>

<style lang="less">
    .fade-enter-active, .fade-leave-active {
        transition: all ease .2s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .container {
        background: #f7f8fa;
        min-height: 100vh;
        box-sizing: border-box;
        padding-bottom: 70px;
    }
    .order-bar {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        z-index: 8;
        justify-content: space-between;
        .order-bar__amount {
            flex: 1;
            display: flex;
            align-items: center;
        }
        .order-bar__confirm {
            font-size: 14pt;
            color: #fff;
            background: #08979C;
            border-radius: 4px;
            flex: 1;
            text-align: center;
            padding: 10px 0;
        }
    }
    .order-section__separater {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 5px;
        margin-top: 15px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .order-section__subscription {
            color: #08979C;
            font-size: 11pt;
        }
    }
    .order-section__hint {
        font-size: 11pt;
        text-align: center;
        color: #a5a6a7;
    }
    .order-section__pay {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #08979C;
        color: #fff;
        text-align: center;
        padding: 12px;
        z-index: 10;
    }
    .order-section {
        background: #fff;
        padding-left: 15px;
        margin-bottom: 10px;
        .order-section__title {
            text-align: center;
            padding: 15px;
            padding-left: 0;
            font-size: 13pt;
            font-weight: 400;
            position: relative;
            .order-section__cancel {
                position: absolute;
                height: 24px;
                left: 5px;
                top: 16px;
            }
            .order-section__tag {
                position: absolute;
                right: 15px;
                top: 16px;
                font-size: 11pt;
                font-weight: 300;
            }
        }
        .order-section__item {
            padding-right: 15px;
            border-bottom: 1px solid #eee;
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            align-items: flex-start;
            &:last-child {
                border-bottom: none;
            }
            .order-section__checkbox {
                padding-right: 10px;
                padding-top: 2px;
                &.channel {
                    padding-right: 0;
                    padding-left: 10px;
                }
                img {
                    width: 16px;
                    height: 16px;
                }
            }
            .order-section__name {
                width: 90px;
                color: #646566;
            }
            .order-section__content {
                flex: 1;
                color: #323233;
                &.payment {
                    text-align: right;
                }
                &.empty {
                    text-align: center;
                    font-size: 10pt;
                    color: #a5a6a7;
                    padding-top: 15px;
                    padding-bottom: 15px;
                }
                .payment-remark {
                    font-size: 10pt;
                    color: #646566;
                    &.warning {
                        color: #D81E06;
                    }
                }
            }
        }
    }
</style>



