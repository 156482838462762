<template>
    <div>
        <div class="order-section__subscription" @click="cancel" v-if="detail.has_subscribe">已订阅付款提醒</div>
        <div class="order-section__subscription" @click="subscribe" v-else>订阅付款提醒</div>

        <code-drawer :toggle='subscribing' :title='"订阅付款详情"' v-on:close='close'>
            <img :src="qrcode" v-if="qrcode" class="qrcode-image">
            <p style="color: #999;text-align: center">扫码关注公众号后即完成订阅</p>
        </code-drawer>
    </div>
</template>

<script>
    const weui = window.weui
    import { mapActions } from 'vuex'
    import CodeDrawer from '../Apartment/drawer'

    export default {
        props: ['detail', 'openid'],
        components: {
            CodeDrawer
        },
        data () {
            return {
                subscribing: false,
                qrcode: ''
            }
        },
        methods: {
            ...mapActions ({
                subscribeOrderMessage: 'subscribeOrderMessage',
                cancelSubscribeOrder: 'cancelSubscribeOrder'
            }),
            subscribe () {
                const that = this
                this.subscribeOrderMessage({
                    data: this.detail.id,
                    success: res => {
                        // console.log(res)
                        that.qrcode = res.image_url
                        that.subscribing = true
                    }
                })
            },
            close () {
                this.subscribing = false
                this.$emit('update')
            },
            cancel () {
                const that = this
                weui.confirm('取消订阅？', {
                    buttons: [{
                        label: '继续订阅',
                        type: 'default'
                    },{
                        label: '取消订阅',
                        type: 'primary',
                        onClick: () => {
                            that.cancelSubscribeOrder({
                                data: {
                                    openid: that.openid,
                                    order_id: that.detail.id
                                },
                                success: () => {
                                    that.$emit('update')
                                }
                            })
                        }
                    }]
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .qrcode-image {
        display: block;
        width: 80%;
        margin: 15px auto;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.15);
    }
</style>
