<template>
    <div class="tag-list">
        <div v-for="(tag, index) in list" :key="`tag-${index}`" class="tag-list__item">
            #{{tag}}
        </div>
    </div>
</template>

<script>
    export default {
        props: ['list']
    }
</script>

<style lang="less" scoped>
    .tag-list {
        margin-top: 5px;
        margin-bottom: 10px;
        overflow: hidden;
        .tag-list__item {
            font-size: 11pt;
            color: #08979C;
            background: #f3f4f5;
            border-radius: 15px;
            padding: 2px 10px;
            float: left;
            margin-top: 5px;
            margin-right: 5px;
        }
    }
</style>
