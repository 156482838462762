import api from './_api'
import * as types from './_types'
import { ActionBuilder } from '@/vuex/api/builder'

const viewApartmentDetail = ActionBuilder({
    method: api.viewApartment,
    actionStart: types.REQUESTING_APARTMENT_DETAIL,
    actionSuccess: types.REQUESTING_APARTMENT_DETAIL_SUCCESS,
    actionFail: types.REQUESTING_APARTMENT_DETAIL_FAIL
})

const viewLayoutDetail = ActionBuilder({
    method: api.viewLayout,
    actionStart: types.REQUESTING_LAYOUT_DETAIL,
    actionSuccess: types.REQUESTING_LAYOUT_DETAIL_SUCCESS,
    actionFail: types.REQUESTING_LAYOUT_DETAIL_FAIL
})

const clickApartmentPhone = ActionBuilder({
    method: api.checkPhone,
    actionStart: types.UPDATE_APARTMENT_DETAIL,
    actionSuccess: types.UPDATE_APARTMENT_DETAIL_SUCCESS,
    actionFail: types.UPDATE_APARTMENT_DETAIL_FAIL
})

export default {
    actions: {
        viewApartmentDetail,
        viewLayoutDetail,
        clickApartmentPhone
    }
}