import api from './_api'
import * as types from './_types'
import { ActionBuilder } from '@/vuex/api/builder'

const viewTopicDetail = ActionBuilder({
    method: api.viewTopic,
    actionStart: types.REQUESTING_TOPIC_DETAIL,
    actionSuccess: types.REQUESTING_TOPIC_DETAIL_SUCCESS,
    actionFail: types.REQUESTING_TOPIC_DETAIL_FAIL
})

export default {
    actions: {
        viewTopicDetail
    }
}