<template>
    <div class='container'>
        <loading v-if='!detail && requesting'/>
        <div v-if='detail' class='house-detail'>
            <house-gallery :detail='detail'/>
            <house-title :detail='detail' :player='player' v-on:play='generate' v-on:open='jump'/>
            <div class='house-section'>
                <house-location :detail='detail'/>
                <!-- <house-category :detail='detail'/> -->
                <house-feature :detail='detail'/>
            </div>

            <house-collect :detail='detail'/>
            <house-banner :detail='detail' v-on:open='jump'/>
            <house-videos :videos='detail.room.bed.videos' :player='player'/>
        </div>
    </div>
</template>

<script>
    const Swiper = window.Swiper
    import { mapState, mapActions } from 'vuex'
    import tooltip from '@/mixins/tooltip'
    import Loading from '@/components/Common/loading'
    import HouseGallery from '@/components/House/gallery'
    import HouseTitle from '@/components/House/title'
    import HouseLocation from '@/components/House/location'
    // import HouseCategory from '@/components/House/category'
    import HouseFeature from '@/components/House/feature'
    import HouseCollect from '@/components/House/collect'
    import HouseBanner from '@/components/House/banner'
    import HouseVideos from '@/components/House/videos'

    export default {
        mixins: [tooltip],
        data () {
            return {
                player: {
                    playing: 0,
                    toggle: false
                }
            }
        },
        components: {
            Loading,
            HouseGallery,
            HouseTitle,
            HouseLocation,
            // HouseCategory,
            HouseFeature,
            HouseCollect,
            HouseBanner,
            HouseVideos
        },
        computed: {
            ...mapState({
                requesting: state => state.HouseModule.requesting,
                detail: state => state.HouseModule.detail,
                updating: state => state.HouseModule.updating
            })
        },
        methods: {
            ...mapActions({
                viewHouseDetail: 'viewHouseDetail'
            }),
            update () {
                const that = this
                this.viewHouseDetail({
                    data: this.$route.name == 'house' ? {
                        bed_id: this.$route.params.id,
                        source_uid: this.$route.query.source_uid
                    } : { sale_id: this.$route.params.id },
                    success: () => {
                        setTimeout(() => {
                            that.init()
                        }, 300)
                    }
                })
            },
            init () {
                const gallerySwiper = new Swiper('.swiper-container', {
                    speed: 400,
                    pagination: {
                        el: ".swiper-pagination",
                        bulletClass: 'swiper-dot',
                        bulletActiveClass: 'swiper-dot-active'
                    }
                })
            },
            generate () {
                const that = this
                setTimeout(() => {
                    const videoSwiper = new Swiper('.video-swiper-container', {
                        speed: 400
                    })
                    videoSwiper.on('slideChange', e => {
                        const id = `video-${this.detail.room.bed.videos[that.player.playing].id}`
                        const video = document.getElementById(id)
                        video.pause()
                        that.player.playing = e.activeIndex
                    })
                }, 400)
            },
            jump (message) {
                this.open(`bed/${this.$route.params.id}`, message)
            }
        },
        mounted () {
            this.update()
        },
        watch: {
            'updating': function () {
                if (!this.updating) this.update()
            }
        }
    }
</script>

<style lang="less">
    .container {
        background: #fff;
        min-height: 100vh;
    }
    .house-section {
        background: #fff;
        padding: 10px 15px;
        border-top: 10px solid #f3f5f7;
        padding-bottom: 5px;
        padding-bottom: 120px;
        .house-info {
            display: flex;
            align-items: flex-start;
            font-size: 10pt;
            margin-bottom: 10px;
            .house-info__label {
                color: #b1b1b1;
                width: 80px;
            }
            .house-info__content {
                word-break: break-all;
                color: #111;
                flex: 1;
            }
        }
    }
</style>



