<template>
    <div class='apartment-description' v-html='apartment.description.replace(/\n/g,"<br>")'>
    </div>
</template>

<script>
    export default {
        props: ['apartment']
    }
</script>

<style lang="less" scoped>
    .apartment-description {
        font-size: 11pt;
        color: #646566;
        padding: 15px 0;
        border-top: 1px solid #eee;
    }
</style>

