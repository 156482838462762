import api from './_api'
import * as types from './_types'
import { ActionBuilder } from '@/vuex/api/builder'

const viewHouseDetail = ActionBuilder({
    method: api.viewHouse,
    actionStart: types.REQUESTING_HOUSE_DETAIL,
    actionSuccess: types.REQUESTING_HOUSE_DETAIL_SUCCESS,
    actionFail: types.REQUESTING_HOUSE_DETAIL_FAIL
})

const collectHouseDetail = ActionBuilder({
    method: api.collectHouse,
    actionStart: types.UPDATING_HOUSE_DETAIL,
    actionSuccess: types.UPDATING_HOUSE_DETAIL_SUCCESS,
    actionFail: types.UPDATING_HOUSE_DETAIL_FAIL
})

export default {
    actions: {
        viewHouseDetail,
        collectHouseDetail
    }
}