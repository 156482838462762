<template>
    <div class='download-app'>
        <img src='@/assets/logo384px.png' class='download-app__logo'/>
        <h2>六六合作伙伴</h2>
        <p class='context'>专业管家 用心服务</p>
        <div class='download-app__btn' @click='download'>下载合作伙伴App</div>
        <p class='download-notice'></p>
        <!-- <p class='sub-context'>同时支持个人/商家/合作伙伴</p> -->
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import tooltip from '@/mixins/tooltip'

    export default {
        mixins: [tooltip],
        data () {
            return {
                ios: 'https://apps.apple.com/cn/app/%E5%85%AD%E5%85%AD%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4/id1552672635',
                android: 'http://www.zuber.im/android/agentlatest'
            }
        },
        computed: {
            ...mapState({
                platform: state => state.CommonModule.platform,
                isWechat: state => state.CommonModule.isWechat
            })
        },
        methods: {
            download () {
                if (this.isWechat) {
                    this.jump()
                } else {
                    window.open(this.platform == 'ios' ? this.ios : this.android)
                }
            },
            jump () {
                this.open(this.$route.query.target)
            }
        }
    }
</script>
