import {
    TOGGLE_NOTIFICATION,
    GET_JS_TICKET,
    GET_JS_TICKET_SUCCESS,
    GET_JS_TICKET_FAIL
} from './_types'
import { token } from '@/vuex/api/config'

const UA = window.navigator.userAgent

const state = {
    isWechat: /MicroMessenger/i.test(UA),
    isAlipay: /AlipayClient/.test(UA),
    platform: /(iPhone|iPad|iPod|iOS)/i.test(UA) ? 'ios' : 'android',
    visible: false,
    target: token.getValue('target'),
    generating: false
}

const mutations = {
    [TOGGLE_NOTIFICATION] (state) {
        state.visible = !state.visible
    },
    [GET_JS_TICKET] (state) {
        state.generating = true
    },
    [GET_JS_TICKET_SUCCESS] (state) {
        state.generating = false
    },
    [GET_JS_TICKET_FAIL] (state) {
        state.generating = false
    }
}

export default {
    state,
    mutations
}