import LocalStorage from '../utils/localstorage'

export default class Token extends LocalStorage {
    constructor (prefix, storage) {
        const _prefix = prefix ? prefix : ''
        super (_prefix, storage)
    }
    getToken (key) {
        if (key === 'api') {
            let _uid = super.getValue('uid')
            let _api = _uid ? '/' + _uid : ''
            return _api;
        } else {
            return super.getValue(key)
        }
    }
    setToken (uid, token, secret) {
        super.setValue('uid', uid)
        super.setValue('token', token)
        super.setValue('secret', secret)
    }
    clean () {
        super.removeValue('uid')
        super.removeValue('token')
        super.removeValue('secret')
    }
}