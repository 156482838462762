<template>
    <div class="order-section">
        <div class="order-section__item" v-for="item in channels" :key="item.channel" @click="select(item)">
            <div class="order-section__name channel_name">
                <div>{{item.channel_name}}</div>
                <div style="font-size: 10pt;" v-if="item.channel_fee > 0">手续费：{{item.channel_fee}}</div>
            </div>
            <div class="order-section__content payment">
                <div>￥{{item.total_amount}}</div>
            </div>
            <div class="order-section__checkbox channel">
                <img src="@/assets/checkbox_selected@3x.png" :style="{opacity: channel.selected && item.channel == channel.selected.channel ? 1 : 0}"/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['channels', 'channel'],
        methods: {
            select (item) {
                this.channel.selected = item
            }
        }
    }
</script>

<style lang="less" scoped>
    .channel_name {
        width: auto !important;
        flex: 1.5;
    }
</style>
