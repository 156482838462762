<template>
    <div class='download-app'>
        <p class='sub-context' @click='jump'>
            已安装六六找房?
            <span class='sub-context__link'>
                点此打开应用
            </span>
        </p>
        <img src='@/assets/60pt@3x.png' class='download-app__logo'/>
        <h2>六六找房</h2>
        <p class='context'>真实房源 实拍照片</p>
        <div class='download-app__btn' @click='download'>下载App</div>
        <p class='download-notice' v-if='$route.query.redirect'>{{$route.query.redirect}}</p>
        <!-- <p class='sub-context'>同时支持个人/商家/合作伙伴</p> -->
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import tooltip from '@/mixins/tooltip'

    export default {
        mixins: [tooltip],
        data () {
            return {
                ios: 'https://apps.apple.com/cn/app/%E5%85%AD%E5%85%AD%E7%9B%B4%E7%A7%9F-zuber/id1035547502',
                android: 'http://www.zuber.im/android/latest'
            }
        },
        computed: {
            ...mapState({
                platform: state => state.CommonModule.platform,
                isWechat: state => state.CommonModule.isWechat
            })
        },
        methods: {
            download () {
                if (this.isWechat) {
                    this.jump()
                } else {
                    window.open(this.platform == 'ios' ? this.ios : this.android)
                }
            },
            jump () {
                this.open(this.$route.query.target)
            }
        }
    }
</script>
