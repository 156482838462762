<template>
    <div
    style='padding: 15px 0;'
    v-html='layout.description.replace(/\n/g,"<br>")'/>
</template>

<script>
    export default {
        props: ['layout']
    }
</script>

