<template>
    <div>
        <loading v-if='!detail && requesting'/>
        <div class="container">
            <img src="@/assets/orderdetail_ successed@3x.png" height="64" style="margin-bottom: 15px;"/>
            <div>支付成功</div>

            <div class="success-confirm" @click="view">
                查看订单详情
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import Loading from '@/components/Common/loading'

    export default {
        components: {
            Loading
        },
        computed: {
            ...mapState({
                updating: state => state.OrderModule.updating
            })
        },
        methods: {
            ...mapActions({
                checkPayResult: 'checkPayResult'
            }),
            update () {
                this.checkPayResult({
                    data: this.$route.params.id
                })
            },
            view () {
                this.$router.push({name: 'order', params: {id: this.$route.params.id}})
            }
        },
        mounted () {
            this.update()
        }
    }
</script>

<style lang="less" scoped>
    .container {
        text-align: center;
        padding-top: 100px;
    }
    .success-confirm {
        background: #08979C;
        color: #fff;
        border-radius: 25px;
        padding: 8px;
        width: 240px;
        font-size: 11pt;
        margin: 0 auto;
        margin-top: 80px;
    }
</style>

