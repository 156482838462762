export const REQUESTING_APARTMENT_DETAIL = 'REQUESTING_APARTMENT_DETAIL'
export const REQUESTING_APARTMENT_DETAIL_SUCCESS = 'REQUESTING_APARTMENT_DETAIL_SUCCESS'
export const REQUESTING_APARTMENT_DETAIL_FAIL = 'REQUESTING_APARTMENT_DETAIL_FAIL'
export const REQUESTING_LAYOUT_DETAIL = 'REQUESTING_LAYOUT_DETAIL'
export const REQUESTING_LAYOUT_DETAIL_SUCCESS = 'REQUESTING_LAYOUT_DETAIL_SUCCESS'
export const REQUESTING_LAYOUT_DETAIL_FAIL = 'REQUESTING_LAYOUT_DETAIL_FAIL'
export const UPDATE_APARTMENT_DETAIL = 'UPDATE_APARTMENT_DETAIL'
export const UPDATE_APARTMENT_DETAIL_SUCCESS = 'UPDATE_APARTMENT_DETAIL_SUCCESS'
export const UPDATE_APARTMENT_DETAIL_FAIL = 'UPDATE_APARTMENT_DETAIL_FAIL'


