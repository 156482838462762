export default class LocalStorage {
    prefix = ''
    storage = {}
    constructor (prefix, storage) {
        this.prefix = prefix
        this.storage = storage
    }
    getValue (key) {
        let _key = this.prefix + key
        let _val = this.storage.getItem(_key)
        try {
            return _val ? JSON.parse(_val) : _val
        } catch (e) {
            return _val
        }
    }
    setValue (key, value) {
        let _key = this.prefix + key
        let _value
        try {
            _value = JSON.stringify(value)
        } catch (e) {
            _value = value
        }
        this.storage.setItem(_key, _value)
    }
    removeValue (key) {
        let _key = this.prefix + key
        this.storage.removeItem(_key)
    }
}
