import { render, staticRenderFns } from "./description.vue?vue&type=template&id=759d3b85&scoped=true&"
import script from "./description.vue?vue&type=script&lang=js&"
export * from "./description.vue?vue&type=script&lang=js&"
import style0 from "./description.vue?vue&type=style&index=0&id=759d3b85&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "759d3b85",
  null
  
)

export default component.exports