<template>
    <div class='drawer-wrapper'>
        <div class='drawer-mask' v-if='toggle' @click='close'></div>
        <transition name='slide' mode='out-in'>
            <div class='drawer-container' v-if='toggle'>
                <div class='drawer-container__title'>
                    <img src='@/assets/navbar_btn_delete@3x.png'
                    class='drawer-container__close'
                    @click.stop='close'/>
                    {{title}}
                </div>
                <div class='drawer-content'>
                    <slot/>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        props: ['toggle', 'title'],
        methods: {
            close () {
                this.$emit('close')
            }
        }
    }
</script>

<style lang='less' scoped>
    .drawer-mask {
        position: fixed;
        background: #333;
        opacity: .75;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 9;
    }
    .drawer-container {
        position: fixed;
        background: #fff;
        left: 0;
        top: 100px;
        right: 0;
        bottom: 0;
        z-index: 10;
        box-sizing: border-box;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        flex-direction: column;
        .drawer-container__title {
            text-align: center;
            padding: 10px;
            position: relative;
            border-bottom: 1px solid #eee;
            .drawer-container__close {
                position: absolute;
                left: 12px;
                top: 14px;
                width: 16px;
                height: 16px;
            }
        }
        .drawer-content {
            flex: 1;
            min-height: 0px;
            overflow-y: auto;
            padding: 10px;
        }
    }
    .slide-enter-active, .slide-leave-active {
        transition: all ease .3s;
        opacity: 1;
    }
    .slide-enter, .slide-leave-to {
        opacity: 0;
        transform: translateY(100%);
    }
</style>


