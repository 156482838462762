import api from './_api'
import * as types from './_types'
import { ActionBuilder } from '@/vuex/api/builder'

const viewAgentStatistic = ActionBuilder({
    method: api.viewStatistic,
    actionStart: types.REQUESTING_AGENT_STATISTIC,
    actionSuccess: types.REQUESTING_AGENT_STATISTIC_SUCCESS,
    actionFail: types.REQUESTING_AGENT_STATISTIC_FAIL
})

export default {
    actions: {
        viewAgentStatistic
    }
}