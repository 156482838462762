import {
    GET_USER_AUTH,
    GET_USER_AUTH_SUCCESS,
    GET_USER_AUTH_FAIL,
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAIL,
    GET_USER_CODE,
    GET_USER_CODE_SUCCESS,
    GET_USER_CODE_FAIL,
    GET_OPEN_ID,
    GET_OPEN_ID_SUCCESS,
    GET_OPEN_ID_FAIL
} from './_types'

import { token } from '@/vuex/api/config'

const state = {
    logging: false,
    ifLogged: token.getToken('uid') && true,
    current_uid: token.getToken('uid'),
    current_openid: token.getToken('openid'),
    requesting: false,
    profile: null,
    generating: false,
    identity_code: null
}

const mutations = {
    [GET_USER_AUTH] (state) {
        state.logging = true
    },
    [GET_USER_AUTH_SUCCESS] (state, action) {
        token.setToken(action.result.uid, action.result.token, action.result.secret)
        state.logging = false
    },
    [GET_USER_AUTH_FAIL] (state) {
        state.ifLogged = false
        state.logging = false
    },
    [GET_USER_PROFILE] (state) {
        state.requesting = true
    },
    [GET_USER_PROFILE_SUCCESS] (state, action) {
        state.profile = action.result
        state.requesting = false
    },
    [GET_USER_PROFILE_FAIL] (state) {
        state.requesting = false
    },
    [GET_USER_CODE] (state) {
        state.generating = true
    },
    [GET_USER_CODE_SUCCESS] (state, action) {
        state.generating = false
        state.identity_code = action.result
    },
    [GET_USER_CODE_FAIL] (state) {
        state.generating = false
        state.identity_code = null
    },
    [GET_OPEN_ID] (state) {
        state.logging = true
    },
    [GET_OPEN_ID_SUCCESS] (state, action) {
        state.logging = false
        state.current_openid = action.result
        token.setValue('openid', state.current_openid)
    },
    [GET_OPEN_ID_FAIL] (state) {
        state.logging = false
        state.current_openid = ''
        token.setValue('openid', '')
    }
}

export default {
    state,
    mutations
}
