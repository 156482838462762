<template>
    <div class="order-section">
        <div class="order-section__item" v-for="item in detail.unpayed_projects" :key="item.id" @click="select(item.id)">
            <div class="order-section__checkbox">
                <img src="@/assets/checkbox_normal@3x.png" v-if='selected.indexOf(item.id) < 0'/>
                <img src="@/assets/checkbox_selected@3x.png" v-else/>
            </div>
            <div class="order-section__name">{{types[item.type]}}</div>
            <div class="order-section__content payment">
                <div>￥{{item.payment_amount}}</div>
                <div class="payment-remark" v-if="item.remark">{{item.remark}}</div>
                <div class="payment-remark warning" v-if="item.is_show_delay_unpayed_notice">7天未付款</div>
            </div>
        </div>
        <div class="order-section__item" v-if="detail.unpayed_projects.length <= 0">
            <div class="order-section__content empty">
                没有待付款项
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { ORDER_PAY_TPYES } from '@/vuex/modules/order/_model'

    export default {
        props: ['detail', 'selected'],
        data () {
            return {
                types: ORDER_PAY_TPYES
            }
        },
        computed: {
            ...mapState({
                estimating: state => state.OrderModule.estimating
            })
        },
        methods: {
            ...mapActions({
                cleanEstimate: 'cleanEstimate'
            }),
            select (id) {
                const index = this.selected.indexOf(id)
                if (!this.estimating) {
                    if (index >= 0) {
                        this.selected.splice(index, 1)
                    } else {
                        this.selected.push(id)
                    }
                    if (this.selected.length > 0) this.$emit('estimate')
                    else this.cleanEstimate()
                }
            }
        }
    }
</script>