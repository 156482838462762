import {
    REQUESTING_APARTMENT_DETAIL,
    REQUESTING_APARTMENT_DETAIL_SUCCESS,
    REQUESTING_APARTMENT_DETAIL_FAIL,
    REQUESTING_LAYOUT_DETAIL,
    REQUESTING_LAYOUT_DETAIL_SUCCESS,
    REQUESTING_LAYOUT_DETAIL_FAIL,
    UPDATE_APARTMENT_DETAIL,
    UPDATE_APARTMENT_DETAIL_SUCCESS,
    UPDATE_APARTMENT_DETAIL_FAIL
} from './_types'

const state = {
    requesting: false,
    detail: null,
    unexistance: false,
    requesting_layout: false,
    layout_unexistance: false,
    layout: null,
    updating: false
}

const mutations = {
    [REQUESTING_APARTMENT_DETAIL] (state) {
        state.requesting = true
        state.unexistance = false
    },
    [REQUESTING_APARTMENT_DETAIL_SUCCESS] (state, action) {
        state.detail = action.result
        state.requesting = false
        state.unexistance = false
    },
    [REQUESTING_APARTMENT_DETAIL_FAIL] (state) {
        state.detail = null
        state.requesting = false
        state.unexistance = true
    },
    [REQUESTING_LAYOUT_DETAIL] (state) {
        state.requesting_layout = true
        state.layout_unexistance = false
        state.layout = null
    },
    [REQUESTING_LAYOUT_DETAIL_SUCCESS] (state, action) {
        state.layout = action.result
        state.requesting_layout = false
        state.layout_unexistance = false
    },
    [REQUESTING_LAYOUT_DETAIL_FAIL] (state) {
        state.layout = null
        state.requesting_layout = false
        state.layout_unexistance = true
    },
    [UPDATE_APARTMENT_DETAIL] (state) {
        state.updating = true
    },
    [UPDATE_APARTMENT_DETAIL_SUCCESS] (state) {
        state.updating = false
    },
    [UPDATE_APARTMENT_DETAIL_FAIL] (state) {
        state.updating = false
    }
}

export default {
    state,
    mutations
}