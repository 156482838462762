import api from './_api'
import * as types from './_types'
import { ActionBuilder } from '@/vuex/api/builder'

const getUserAuth = ActionBuilder({
    method: api.getAuth,
    actionStart: types.GET_USER_AUTH,
    actionSuccess: types.GET_USER_AUTH_SUCCESS,
    actionFail: types.GET_USER_AUTH_FAIL
})

const getUserProfile = ActionBuilder({
    method: api.getProfile,
    actionStart: types.GET_USER_PROFILE,
    actionSuccess: types.GET_USER_PROFILE_SUCCESS,
    actionFail: types.GET_USER_PROFILE_FAIL
})

const getUserCode = ActionBuilder({
    method: api.getCode,
    actionStart: types.GET_USER_CODE,
    actionSuccess: types.GET_USER_CODE_SUCCESS,
    actionFail: types.GET_USER_CODE_FAIL
})

const getUserOpenId = ActionBuilder({
    method: api.getOpenId,
    actionStart: types.GET_OPEN_ID,
    actionSuccess: types.GET_OPEN_ID_SUCCESS,
    actionFail: types.GET_OPEN_ID_FAIL
})

export default {
    actions: {
        getUserAuth,
        getUserProfile,
        getUserCode,
        getUserOpenId
    }
}