<template>
    <div class='house-collect' @click='collect'>
        <img src='@/assets/html_wechatint_btn_collect_selected@3x.png' class='house-collect__icon' v-if='detail.is_follow'/>
        <img src='@/assets/html_wechatint_btn_collect_normal@3x.png' class='house-collect__icon' v-else/>
        <div class='house-collect__text' :style='{color: detail.is_follow ? "#08979C" : "#666"}'>{{detail.is_follow ? '已收藏' : '收藏'}}</div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        props: ['detail'],
        computed: {
            ...mapState({
                updating: state => state.HouseModule.updating
            })
        },
        methods: {
            ...mapActions({
                collectHouseDetail: 'collectHouseDetail'
            }),
            collect () {
                if (!this.updating) {
                    this.collectHouseDetail({
                        data: {
                            follow: this.detail.is_follow,
                            id: this.$route.params.id,
                            source_uid: this.$route.params.source_uid,
                            route: this.$route.name
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="less">
    .house-collect {
        width: 50px;
        height: 50px;
        padding: 10px;
        background: #fff;
        border-radius: 100%;
        text-align: center;
        position: fixed;
        right: 15px;
        bottom: 180px;
        font-size: 9pt;
        box-shadow: 2px 2px 12px 0 rgba(100, 101, 102, 0.12);
        transition: all ease .2s;
        .house-collect__icon {
            width: 24px;
        }
    }
</style>


