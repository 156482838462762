import {
    REQUESTING_ORDER_DETAIL,
    REQUESTING_ORDER_DETAIL_SUCCESS,
    REQUESTING_ORDER_DETAIL_FAIL,
    UPDATING_ORDER_DETAIL,
    UPDATING_ORDER_DETAIL_SUCCESS,
    UPDATING_ORDER_DETAIL_FAIL,
    REQUESTING_ESTIMATE_DETAIL,
    REQUESTING_ESTIMATE_DETAIL_SUCCESS,
    REQUESTING_ESTIMATE_DETAIL_FAIL,
    REQUESTING_PAYED_LIST,
    REQUESTING_PAYED_LIST_SUCCESS,
    REQUESTING_PAYED_LIST_FAIL,
    CLEAN_ESTIMATE
} from './_types'

const state = {
    requesting: false,
    detail: null,
    unexistance: false,
    updating: false,
    estimating: false,
    payed_list: [],
    payment: null
}

const mutations = {
    [REQUESTING_ORDER_DETAIL] (state) {
        state.requesting = true
        state.unexistance = false
    },
    [REQUESTING_ORDER_DETAIL_SUCCESS] (state, action) {
        state.detail = action.result
        state.requesting = false
        state.unexistance = false
    },
    [REQUESTING_ORDER_DETAIL_FAIL] (state) {
        state.detail = null
        state.requesting = false
        state.unexistance = true
    },
    [UPDATING_ORDER_DETAIL] (state) {
        state.updating = true
    },
    [UPDATING_ORDER_DETAIL_SUCCESS] (state) {
        state.updating = false
    },
    [UPDATING_ORDER_DETAIL_FAIL] (state) {
        state.updating = false
    },
    [REQUESTING_ESTIMATE_DETAIL] (state) {
        state.estimating = true
    },
    [REQUESTING_ESTIMATE_DETAIL_SUCCESS] (state, action) {
        state.payment = action.result
        state.estimating = false
    },
    [REQUESTING_ESTIMATE_DETAIL_FAIL] (state) {
        state.payment = null
        state.estimating = false
    },
    [CLEAN_ESTIMATE] (state) {
        state.payment = null
    },
    [REQUESTING_PAYED_LIST] (state) {
        state.requesting = true
    },
    [REQUESTING_PAYED_LIST_SUCCESS] (state, action) {
        state.requesting = false
        state.payed_list = action.result.items
    },
    [REQUESTING_PAYED_LIST_FAIL] (state) {
        state.requesting = false
    }
}

export default {
    state,
    mutations
}