<template>
    <div>
        <div class="house-info" v-for='(item, index) in detail.text_map' :key='`prop-${index}`'>
            <div class="house-info__label">
                {{item.name}}：
            </div>
            <div class="house-info__content">
                <div v-html='item.value.replace(/\n/g,"<br>")'/>
            </div>
            <div
            v-if='item.type == "copy"'
            class='house-info__opt'
             :data-clipboard-text='item.value'>
                复制
            </div>
        </div>
    </div>
</template>

<script>
    import tooltip from '@/mixins/tooltip'

    export default {
        props: ['detail'],
        mixins: [tooltip],
        mounted () {
            this.copy('.house-info__opt')
        }
    }
</script>

<style lang="less">
    .house-info__opt {
        color: #4A90E2;
        font-size: 9pt;
    }
</style>
