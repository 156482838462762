import PhotoSwipe from 'photoswipe/dist/photoswipe.min'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default.min'

export default {
    methods: {
        view (index, images, closed, slided) {
            let swipeEl = document.querySelectorAll('.pswp')[0]
            let options = {
                index: index,
                zoomEl: true
            }
            let items = []
            images.forEach(item => {
                let image = {
                    src: item.src,
                    w: item.width ? item.width : 500,
                    h: item.height ? item.height : 500
                }
                items.push(image)
            })
            let gallery = new PhotoSwipe(swipeEl, PhotoSwipeUIDefault, items, options)
            gallery.listen('close', function() {
                if (closed) closed()
            })
            gallery.listen('afterChange', () => {
                if (slided) slided(gallery.getCurrentIndex())
            })
            gallery.init()
            return gallery
        }
    }
}