import { mapState, mapActions } from 'vuex'

export default {
    computed: {
        ...mapState({
            logging: state => state.AuthModule.logging
        })
    },
    methods: {
        ...mapActions({
            getUserOpenId: 'getUserOpenId'
        }),
        auth (callback) {
            // const that = this
            if (!this.logging) {
                this.getUserOpenId({
                    data: this.$route.query,
                    success: () => {
                        // that.$router.replace({path: that.target})
                        if (callback) callback()
                    },
                    fail: () => {
                        if (callback) callback()
                    }
                })
            }
        }
    }
}