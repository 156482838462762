<template>
    <div>
        <div class='apartment-gallery' @click='enjoy'>
            <div class='apartment-gallery__span' style='margin-bottom: 4px;'>
                <div class='apartment-gallery__item'
                :style='{backgroundImage: `url(${apartment.photos[0].medium})`}'/>
                <div class='apartment-gallery__item'
                :style='{backgroundImage: `url(${apartment.photos[1].medium})`}'/>
            </div>
            <div class='apartment-gallery__span'>
                <div class='apartment-gallery__item small'
                :style='{backgroundImage: `url(${apartment.photos[2].medium})`}'/>
                <div class='apartment-gallery__item small'
                :style='{backgroundImage: `url(${apartment.photos[3].medium})`}'/>
                <div class='apartment-gallery__item small'
                :style='{backgroundImage: `url(${apartment.photos[4].medium})`}'/>
            </div>

            <div class='apartment-gallery__count'>
                <img src='@/assets/picture@3x.png'/>
                {{apartment.photos.length}}
            </div>
        </div>

        <gallery-drawer :toggle='viewing' :title='"环境照片"' v-on:close='close'>
            <album-photos :photos='apartment.photos'/>
        </gallery-drawer>
    </div>
</template>

<script>
    import GalleryDrawer from './drawer'
    import AlbumPhotos from './album'

    export default {
        props: ['apartment'],
        components: { 
            GalleryDrawer,
            AlbumPhotos
        },
        data () {
            return {
                viewing: false
            }
        },
        methods: {
            enjoy () {
                this.viewing = true
            },
            close () {
                this.viewing = false
            }
        }
    }
</script>

<style lang="less" scoped>
    .apartment-gallery {
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        .apartment-gallery__span {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .apartment-gallery__item {
                width: calc((100% - 4px)/2);
                height: 140px;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                &.small {
                    width: calc((100% - 8px)/3);
                    height: 90px;
                }
            }
        }
        .apartment-gallery__count {
            position: absolute;
            right: 10px;
            bottom: 10px;
            font-size: 8pt;
            background: #000;
            color: #fff;
            display: flex;
            align-items: center;
            padding: 1px 5px;
            border-radius: 4px;
            opacity: .7;
            img {
                height: 20px;
                margin-right: 4px;
            }
        }
    }
</style>


