import api from './_api'
import * as types from './_types'
import { ActionBuilder } from '@/vuex/api/builder'

const toggleNotification = ActionBuilder({
    actionSuccess: types.TOGGLE_NOTIFICATION
})

const getJsTicket = ActionBuilder({
    method: api.getTicket,
    actionStart: types.GET_JS_TICKET,
    actionSuccess: types.GET_JS_TICKET_SUCCESS,
    actionFail: types.GET_JS_TICKET_FAIL
})

export default {
    actions: {
        toggleNotification,
        getJsTicket
    }
}