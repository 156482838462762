<template>
    <div class="order-section">
        <div class="order-section__item" v-for="item in list" :key="item.id">
            <div class="order-section__name">{{types[item.type]}}</div>
            <div class="order-section__content payment">
                <div>{{item.payment_amount}}元</div>
                <div class="payment-remark" v-if="item.remark">{{item.remark}}</div>
                <div class="payment-remark">实际支付：{{item.actual_amount}}元</div>
                <div class="payment-remark">
                    付款时间：{{item.payment_time}}
                    <img src="@/assets/payed@3x.png" height="10px"/>
                </div>
            </div>
        </div>
        <div class="order-section__item" v-if="list.length <= 0">
            <div class="order-section__content empty">
                没有已付款项
            </div>
        </div>
    </div>
</template>

<script>
    import { ORDER_PAY_TPYES } from '@/vuex/modules/order/_model'

    export default {
        props: ['list'],
        data () {
            return {
                types: ORDER_PAY_TPYES
            }
        }
    }
</script>