<template>
    <div class='data-container'>
        <div v-if='statistic'>
            <div class='statistic-selection' v-if='statistic.select_user'>
                {{statistic.select_user.manager_username}}
                <img src='@/assets/icon_down_li_s@3x.png' class='statistic-selection__icon'/>

                <select v-model='select_uid'>
                    <option v-for='user in statistic.agent_users' :key='user.id' :value='user.bind_uid'>
                        {{user.manager_username}}
                    </option>
                </select>
            </div>
            <div class='statistic-selection' v-else>
                {{statistic.title}}
            </div>
            <data-table :title='"今日数据"' :list='statistic.data.today'/>
            <data-table :title='"总数据"' :list='statistic.data.all'/>
        </div>
        <loading v-if='requesting'/>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import Loading from '@/components/Common/loading'
    import DataTable from './_table'

    export default {
        components: {
            Loading,
            DataTable
        },
        data () {
            return {
                select_uid: ''
          }
        },
        computed: {
            ...mapState({
                current_uid: state => state.AuthModule.current_uid,
                requesting: state => state.AgencyModule.requesting,
                statistic: state => state.AgencyModule.statistic
            })
        },
        methods: {
            ...mapActions({
                viewAgentStatistic: 'viewAgentStatistic'
            }),
            update () {
                const that = this
                this.viewAgentStatistic({
                    data: this.select_uid,
                    success: () => {
                        if (that.statistic.select_user) {
                            that.select_uid = that.statistic.select_user.bind_uid
                        }
                    }
                })
            }
        },
        mounted () {
            this.select_uid = this.current_uid
            this.update()
        },
        watch: {
            'select_uid': 'update'
        }
    }
</script>

<style lang="less">
    .data-container {
        height: 100vh;
        box-sizing: border-box;
        background: #f7f8fa;
        padding: 15px;
        padding-bottom: 70px;
    }
    .statistic-selection {
        font-size: 11pt;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        align-items: center;
        .statistic-selection__icon {
            height: 14px;
            margin-left: 5px;
            margin-top: -1px;
        }
        select {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: block;
            z-index: 99;
            cursor: pointer;
            opacity: 0;
        }
    }
    .data-section__title {
        display: flex;
        align-items: center;
        font-size: 11pt;
        margin-bottom: 15px;
        .data-section__title_border {
            background: #08979C;
            width: 2px;
            height: 15px;
            margin-right: 15px;
        }
    }
    .data-section {
        background: #fff;
        border-radius: 15px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 30px;
        .data-section__item {
            text-align: center;
            padding: 25px 0;
            width: calc(100% / 3);
            box-sizing: border-box;
            .data-section__item_value {
                font-size: 14pt;
                margin-bottom: 5px;
                font-weight: 400;
            }
            .data-section__item_name {
                font-size: 11pt;
                color: #c8c9cc;
            }
        }
    }
</style>


