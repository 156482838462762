<template>
    <div>
        <loading/>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import Loading from '@/components/Common/loading'

    export default {
        components: {
            Loading
        },
        data () {
            return {
                source: '',
                id: '',
                sid: '',
                source_list: ['app', 'house']
            }
        },
        methods: {
            ...mapActions({
                bindPromotionInfo: 'bindPromotionInfo'
            }),
            sync () {
                this.source = this.source_list.indexOf(this.$route.params.source) >= 0 ? this.$route.params.source : 'app'
                this.id = this.$route.params.id != 'empty' || this.$route.params.id == 'e' ? this.$route.params.id : ''
                this.sid = this.$route.params.sid != 'empty' || this.$route.params.sid == 'e' ? this.$route.params.sid: ''
                if (this.sid) {
                    this.bind()
                } else {
                    this.jump()
                }
            },
            bind () {
                const that = this
                if (this.source !== 'house') {
                    this.bindPromotionInfo({
                        data: {
                            source: this.source,
                            source_id: this.id,
                            source_uid: this.sid
                        },
                        success: () => {
                            that.jump()
                        },
                        fail: () => {
                            that.jump()
                        }
                    })
                } else {
                    this.jump()
                }
            },
            jump () {
                if (this.id && this.source !== 'app') {
                    this.$router.replace({name: this.source, params: {id: this.id}, query: {source_uid: this.sid}})
                } else {
                    this.$router.replace({name: this.source, query: {source_uid: this.sid}})
                }
            }
        },
        mounted () {
            this.sync()
        }
    }
</script>
