<template>
    <video-panel :toggle='player.toggle' v-on:close='close'>
        <div class="video-swiper-container">
            <div class='swiper-wrapper house-videos'>
                <div
                v-for="video in videos"
                :key="`video-${video.id}`"
                class='swiper-slide house-video__item'>
                    <video
                    :src='video.src'
                    controls
                    style='width: 100%;height: 100%'
                    :id='`video-${video.id}`'/>
                </div>
            </div>
        </div>
    </video-panel>
</template>

<script>
    import VideoPanel from '@/components/Common/panel'

    export default {
        components: {
            VideoPanel
        },
        props: ['player', 'videos'],
        methods: {
            close () {
                const video = document.getElementById(`video-${this.videos[this.player.playing].id}`)
                video.pause()
                this.player.toggle = false
            },
            slide (current, pre) {
                this.player.playing = current
                const video = document.getElementById(`video-${this.videos[pre].id}`)
                video.pause()
            }
        }
    }
</script>

<style lang='less' scoped>
    .video-swiper-container {
        height: 100%;
    }
</style>


