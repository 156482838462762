<template>
    <div class='container'>
        <loading v-if='!detail && requesting'/>
        <div v-if='detail' class='topic-detail'>
            <topic-user :detail="detail"/>
            <div class="topic-section">
                <topic-content :detail="detail"/>
                <topic-gallery :detail="detail"/>
                <topic-tags :list="detail.tag_list" v-if="detail.tag_list && detail.tag_list.length > 0"/>
                <topic-stats :detail="detail"/>
            </div>
            <div class="separate-section"></div>
            <topic-comment/>
        </div>
    </div>
</template>

<script>
    const wx = window.wx
    import { mapState, mapActions } from 'vuex'
    import Loading from '@/components/Common/loading'
    import TopicUser from '@/components/Topic/user'
    import TopicContent from '@/components/Topic/content'
    import TopicGallery from '@/components/Topic/gallery'
    import TopicStats from '@/components/Topic/stats'
    import TopicTags from  '@/components/Topic/tags'
    import TopicComment from '@/components/Topic/comment'

    export default {
        components: {
            Loading,
            TopicUser,
            TopicContent,
            TopicGallery,
            TopicStats,
            TopicTags,
            TopicComment
        },
        computed: {
            ...mapState({
                requesting: state => state.TopicModule.requesting,
                detail: state => state.TopicModule.detail,
                updating: state => state.TopicModule.updating
            })
        },
        methods: {
            ...mapActions({
                viewTopicDetail: 'viewTopicDetail',
                getJsTicket: 'getJsTicket'
            }),
            update () {
                const that = this
                this.viewTopicDetail({
                    data: this.$route.params.id,
                    success: () => {
                        that.share()
                    }
                })
            },
            share () {
                const that = this
                this.getJsTicket({
                    data: `${window.location.protocol}//${window.location.host}/${this.$route.name}/${this.$route.params.id}`,
                    success: res => {
                        // console.log(res)
                        wx.config({
                            debug: false,
                            ...res,
                            jsApiList: ['onMenuShareAppMessage', 'onMenuShareTimeline']
                        })
                        that.config()
                    }
                })
            },
            config () {
                const that = this
                wx.ready(() => {
                    wx.onMenuShareAppMessage({
                        title: that.detail.share.title,
                        desc: that.detail.share.content,
                        link: that.detail.share.weixin,
                        imgUrl: that.detail.share.photo.small
                    })
                    wx.onMenuShareTimeline({
                        title: that.detail.share.title,
                        link: that.detail.share.weixin,
                        imgUrl: that.detail.share.photo.small,
                    })
                })
            }
        },
        mounted () {
            this.update()
        }
    }
</script>

<style lang="less">
    .container {
        padding: 10px 15px;
        background: #fff;
    }
    .photo {
        background-color: #f3f4f5;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        box-sizing: border-box;
        border: 1px solid #eee;
    }
    .topic-section {
        padding-left: 50px;
        margin-top: 5px;
    }
</style>



