<template>
    <div>
        <agent-app v-if='$route.query.type == "agent"'/>
        <client-app v-else/>
    </div>
</template>

<script>
    // import { mapState } from 'vuex'
    import ClientApp from './_client'
    import AgentApp from './_agent'

    export default {
        components: {
            ClientApp,
            AgentApp
        }
    }
</script>
