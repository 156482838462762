import {
    REQUESTING_AGENT_STATISTIC,
    REQUESTING_AGENT_STATISTIC_SUCCESS,
    REQUESTING_AGENT_STATISTIC_FAIL
} from './_types'

const state = {
    requesting: false,
    statistic: null
}

const mutations = {
    [REQUESTING_AGENT_STATISTIC] (state) {
        state.requesting = true
    },
    [REQUESTING_AGENT_STATISTIC_SUCCESS] (state, action) {
        state.statistic = action.result
        state.requesting = false
    },
    [REQUESTING_AGENT_STATISTIC_FAIL] (state) {
        state.requesting = false
        state.statistic = null
    }
}

export default {
    state,
    mutations
}