<template>
    <div class='container'>
        <loading v-if='!detail && requesting'/>
        <div v-if='detail' class='house-detail'>
            <apartment-gallery :apartment='detail.apartment'/>
            <apartment-title :apartment='detail.apartment'/>
            <apartment-traffic :apartment='detail.apartment'/>
            <apartment-description :apartment='detail.apartment'/>
            <div class='separate-section'/>
            <h3 class='section-title'>
                <span>房型</span>

                <span style='font-size: 10pt;color: #969799'>
                    {{detail.apartment.change_left_number_time_label}}
                </span>
            </h3>
            <div v-for='layout in detail.apartment.layouts' :key='layout.id'>
                <apartment-layout :layout='layout'/>
            </div>
            <apartment-banner :apartment='detail.apartment' v-on:open='jump'/>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import tooltip from '@/mixins/tooltip'
    import Loading from '@/components/Common/loading'
    import ApartmentGallery from '@/components/Apartment/gallery'
    import ApartmentTitle from '@/components/Apartment/title'
    import ApartmentTraffic from '@/components/Apartment/traffic'
    import ApartmentDescription from '@/components/Apartment/description'
    import ApartmentLayout from '@/components/Apartment/layout'
    import ApartmentBanner from '@/components/Apartment/banner'

    export default {
        mixins: [tooltip],
        components: {
            Loading,
            ApartmentGallery,
            ApartmentTitle,
            ApartmentTraffic,
            ApartmentDescription,
            ApartmentLayout,
            ApartmentBanner
        },
        computed: {
            ...mapState({
                requesting: state => state.ApartmentModule.requesting,
                detail: state => state.ApartmentModule.detail
            })
        },
        methods: {
            ...mapActions({
                viewApartmentDetail: 'viewApartmentDetail'
            }),
            update () {
                const that = this
                this.viewApartmentDetail({
                    data: this.$route.params.id,
                })
            },
            jump (message) {
                this.open(`apartment/${this.$route.params.id}`, message)
            }
        },
        mounted () {
            this.update()
        }
    }
</script>

<style lang="less">
    .container {
        background: #fff;
        min-height: 100vh;
        padding: 10px 15px;
        padding-bottom: 83px;
    }
    .section-title {
        margin-top: 10px;
        display:flex;
        align-items:center;
        justify-content:space-between;
    }
</style>



