<template>
    <div class="topic-content">
        <div v-html='detail.content.replace(/\n/g,"<br>")'/>
    </div>
</template>

<script>
    export default {
        props: ['detail']
    }
</script>

<style lang="less" scoped>
    .topic-content {
        font-size: 12pt;
        font-weight: 400;
    }
</style>
