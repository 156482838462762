<template>
    <div class="topic-stats">
        <div class="topic-stats__item">
            <img src="@/assets/like@3x.png" class="topic-stats__icon"/>
            {{detail.like_count}}
        </div>
        <div class="topic-stats__item">
            <img src="@/assets/comment@3x.png" class="topic-stats__icon"/>
            {{detail.comment_count}}
        </div>
        <div class="topic-stats__item">
            <img src="@/assets/share@3x.png" class="topic-stats__icon"/>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['detail']
    }
</script>

<style lang="less" scoped>
    .topic-stats {
        width: 200px;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        .topic-stats__item {
            display: flex;
            align-items: center;
            font-size: 10pt;
            color: #969799;
            .topic-stats__icon {
                height: 15px;
                margin-right: 5px;
            }
        }
    }
</style>
