import Token from '@/interface/authorization/token'
import APIresource from './resource'

const storage = window.localStorage

const API_ROOT = window.location.host == 'm.66zhizu.com'
    // ? 'http://preview.apiservices.zuber.im/client'
    ? 'https://apiservices.zuber.im/'
    // : 'http://preview.apiservices.zuber.im/client'
    : 'https://services.api.zuker.im/'

export const LOGIN_URL = window.location.host == 'm.66zhizu.com'
    ? 'https://www.66zhizu.com/weixin'
    : 'https://www.66zhizu.com/weixin'

export const token = new Token('zuber-', {
    getItem: key => storage.getItem(key),
    setItem: (key, value) => storage.setItem(key, value),
    removeItem: key => storage.removeItem(key)
})

const clientApi = new APIresource(API_ROOT + 'client', 'client', token)
const agentApi = new APIresource(API_ROOT + 'agent', 'agent', token )

export const AuthResource = type => type == 'agent' ? agentApi.resource('/users') : clientApi.resource('/users')
export const CommonResource = () => clientApi.resource('/commons' + token.getToken('api'))
export const ViewResource = version => clientApi.resource(`${version ? '/' + version : ''}/views` + token.getToken('api'))
export const PromotionResource = () => clientApi.resource('/promotion' + token.getToken('api'))
export const FollowResource = () => clientApi.resource('/follows' + token.getToken('api'))
export const UserResource = () => clientApi.resource('/users' + token.getToken('api'))
export const StatisticResource = version => agentApi.resource(`${version ? '/' + version : ''}/statistic` + token.getToken('api'))
export const OrderResource = () => agentApi.resource('/orders')
export const TopicResource = () => clientApi.resource('/topic' + token.getToken('api'))