<template>
    <div class='layout-item' @click='view'>
        <div class='layout-item__poster'
        :style='{backgroundImage: `url(${layout.photo.medium})`}'>
            <img src='@/assets/play-big@3x.png' class='layout-item__video' v-if='layout.video_file_ids'/>
        </div>
        <div class='layout-item__info'>
            <div>
                <div>{{layout.name}}</div>
                <div style="font-size: 9pt;color: #969799">{{layout.layout}}·{{layout.square_meter_label}}</div>
            </div>
            <div>{{layout.money_label}}</div>
        </div>
        <div class='layout-item__data'>
            {{left_number_label}}
        </div>
    </div>
</template>

<script>
    export default {
        props: ['layout'],
        methods: {
            view () {
                this.$router.push({name: 'layout', params: {id: this.layout.id}})
            }
        }
    }
</script>

<style lang="less" scoped>
    .layout-item {
        display: flex;
        align-items: flex-start;
        height: 90px;
        border-bottom: 1px solid #eee;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 11pt;
        .layout-item__poster {
            border-radius: 2px;
            width: 100px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 80px;
            position: relative;
            .layout-item__video {
                position: absolute;
                width: 18px;
                height: 18px;
                right: 10px;
                bottom: 10px;
            }
        }
        .layout-item__info {
            height: 80px;
            flex: 1;
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .layout-item__data {
            width: 60px;
            text-align: right;
            color: #969799;
        }
    }
</style>


