<template>
    <div class="topic-user">
        <div
        class="topic-user__avatar photo"
        :style="{backgroundImage: `url(${detail.user.avatar.medium})`}"/>
        <div class="topic-user__info">
            <div class="topic-user__name">{{detail.user.username}}</div>
            <div class="topic-user__time">
                {{detail.format_create_time}}

                <div v-if="detail.poi_name" class="topic-user__poi" @click="locate">
                    <img src="@/assets/mappin@3x.png" height="13"/>
                    {{detail.poi_name}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['detail'],
        methods: {
            locate () {
                window.open(`https://m.amap.com/navi/?dest=${this.detail.poi_info.longitude},${this.detail.poi_info.latitude}&key=279975e683fc9a1afdb46094d291b1e0&jscode=d800b28f64b18e1bc283de94b678c33f`)
            }
        }
    }
</script>

<style lang="less" scoped>
    .topic-user {
        display: flex;
        align-items: center;
        .topic-user__avatar {
            border-radius: 100%;
            width: 36px;
            height: 36px;
        }
        .topic-user__info {
            padding-left: 15px;
            .topic-user__name {
                font-size: 12pt;
                font-weight: 400;
            }
            .topic-user__time {
                font-size: 10pt;
                color: #967699;
                display: flex;
                align-items: center;
                .topic-user__poi {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;
                }
            }
        }
    }
</style>
